import React, { useState, useEffect } from 'react';
import { Typography, message, Spin } from 'antd';
import style from './Report.module.scss';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { useNavigate, useParams } from 'react-router-dom';
import ReportTopSection from "./ReportTopSection";
import AdminHeader from "./AdminHeader";
import ReportUnavailable from "./ReportUnavailable";
import ReportMidSection from "./MidSectionComponents/ReportMidSection";
import ReportCollapsibleSections from "./CollapsibleSectionComponents/ReportCollapsibleSections";
import AdminFooter from "./AdminFooter";
import SampleReportDetailsContainer from "./SampleReportDetailsContainer";
import { KIT_TYPES } from '@ph/common/constants/KitConstants';
import ReportV1 from './ReportV1/ReportV1';
import Components from '@ph/common/components';
import PHCartHeader from '../Global/PHCartHeader/PHCartHeader';
import { REPORT_PLANS } from '@ph/common/constants/Report/ReportConstants';
import { useCart } from '@ph/common/context/CartContext';

ChartJS.register(ArcElement, Legend);

const { Text } = Typography;

const SHOW_MID_SECTION_KITS = [KIT_TYPES.TYPE_A,KIT_TYPES.TYPE_X]

const Report = (props) => {
    const { connectionInstance, isAdmin, primarySerumDesc, secondarySerumDesc } = props;
    const params = useParams();
    const navigate = useNavigate();
    const user = useSelector((state) => state.userData.userData);
    const [isETypeKit, setIsETypeKit] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [reportExist, setReportExist] = useState(false);
    const [isLoading, setLoading] = useState(null);
    const [recommendedProduct, setRecommendedProduct] = useState(null);
    const [conciergeCare, setConciergeCare] = useState(null);
    const [bundle, setBundle] = useState(false);
    const [code, setCode] = useState();
    const [customKitcode, setCustomKitcode] = useState(params.kitId ? params.kitId : '');
    const [kitType , setKitType] = useState(KIT_TYPES.TYPE_E)
    const [disableContinue, setDisableContinue] = useState(true);
    const [codeHeader, setCodeHeader] = useState();
    const [disableContinueHeader, setDisableContinueHeader] = useState(true);
    const [reportV1, setReportV1] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorStatus, setErrorStatus] = useState('')
    const { updateIncludedProducts, updateMD03UpgradeProducts, updateReportPlan, reportPlan } = useCart()

    let reportContent = "";

    useEffect(() => {
        getReportData();
    }, []);

    const reportDataSuccessHandler = (reportData) => {
        if (reportData && reportData.hasOwnProperty("other_recommendations") &&
            reportData.other_recommendations.length > 0) {
            let recommendation = {}, conciergeKit = {}, productBundle = {}, isPrimarySet = false;
            reportData.other_recommendations.forEach((item) => {
                let finalDescription = (item.description && item.description.short_text) || '';
                item.finalDescription = finalDescription.split(item.name);
                item.showFullDescription = false;
                if([7792250454265, 8013344080106].includes(item.product_id)) {
                    recommendation = item;
                } else if([7836988539129, 8013343064298].includes(item.product_id)) {
                    item.productLabel = "Complete Your Regimen";
                    conciergeKit = item;
                } else if([7802982367481, 8013343949034].includes(item.product_id)) {
                    item.productLabel = "Add Next: Daily Reset Bundle";
                    productBundle = item;
                }
            });
            reportData.serum_recommendations.forEach(item => {
                let shortDesc = (item.description && item.description.short_text) || '';
                let finalDescription = item.shortDesc;
                item.shortDesc = shortDesc.split(item.name);
                item.finalDescription = finalDescription;
                item.showFullDescription = false;
                if(isPrimarySet) {
                    item.productLabel = "Extra Credit: Your Secondary Serum";
                    finalDescription = (item.description && item.description.secondary_short_text) || '';
                    item.isSecondary = true;
                } else {
                    item.productLabel = "The Must Have: Your Primary Serum";
                    finalDescription = (item.description && item.description.primary_short_text) || '';
                    item.isPrimary = true;
                    isPrimarySet = true;
                }
                item.finalDescription = finalDescription.split(item.name);
            });
            setRecommendedProduct(recommendation);
            setConciergeCare(conciergeKit);
            setBundle(productBundle);

        }
        reportData.constellationType = (reportData.skin_type_name) ?
            Number((reportData.skin_type_name).replace("Type ", "")) : 1;
        // if (reportData.bad_bacteria && reportData.bad_bacteria.length > 5)
        //     reportData.bad_bacteria = reportData.bad_bacteria.slice(0, 5);
        // if (reportData.good_bacteria && reportData.good_bacteria.length > 5)
        //     reportData.good_bacteria = reportData.good_bacteria.slice(0, 5);
        if (reportData?.md_03_subscription) {
            updateReportPlan(REPORT_PLANS.MD03)
        } else {
            updateReportPlan(REPORT_PLANS.TESTER)

        }
        setReportData(reportData);
        updateIncludedProducts([...reportData?.serum_recommendations, ...reportData?.other_recommendations])
        updateMD03UpgradeProducts([...reportData?.serum_recommendations])
        setReportExist(true);
        if(!reportData.microbiome_diversity) {
            setReportExist(false);
        }
    };

    async function getReportData(kitcode, loading = true) {
        let kitCode = 'all';
        if(customKitcode) {
            kitCode = customKitcode
            setKitType(kitCode[0])
        }else if (kitcode) { 
            kitCode = kitcode
            setCustomKitcode(kitCode);
            setKitType(kitCode[0])
        }
        if (loading) {
            setLoading(true); 
        }
        if(isAdmin)
            kitCode = ("" || (kitcode ? kitcode : params.kitId)).toUpperCase();
        setIsETypeKit(kitCode[0] === KIT_TYPES.TYPE_E);
        try {
            const res = await connectionInstance.get(
                `${process.env.REACT_APP_API_URL}/kit/${kitCode}/report`
            ); //.replace('.dev', '.qa')
            if (res) {
                setLoading(false);
                if (res.data && res.data.length > 0) {
                    setIsETypeKit(res.data[0].kitcode[0] === 'E');
                    setKitType(res.data[0].kitcode[0])
                    reportDataSuccessHandler(res.data[0]);
                    setReportV1(res.data[0]?.report_version === 2)
                } else {
                    setReportExist(false);
                }
            } else {
                setReportExist(false);
            }
        } catch (error) {
            setReportExist(false);
            setLoading(false);

            if (error?.response?.data?.message) {
                // if (!(error.response.data.message.toString().toLowerCase().includes('invalid kitcode'))) {
                //     message.error(error.response.data.message);
                // }
                setErrorMessage(error.response.data.message)
            } else {
                message.error('Something went wrong!');
            }
            if (error?.response?.data?.status) {
                setErrorStatus(error?.response?.data?.status)
            }
        }
    }

    const handleInputChange = (input, forHeader) => {
        if(forHeader) {
            setCodeHeader(input);
            setCode("");
            setDisableContinue(true);
            setDisableContinueHeader(!(input && input.toString().length === 7));
            return ;
        }
        setCode(input);
        setCodeHeader("");
        setDisableContinueHeader(true);
        setDisableContinue(!(input && input.toString().length === 7));
    };

    const reportSearchClickHandler = (kitCode) => {
        kitCode = kitCode.toString().toUpperCase();
        //navigate('/report/' + kitCode);
        window.open(`/report/${kitCode}`, '_blank')
        // getReportData(kitCode);
    };

    if (isLoading) {
        reportContent = <div className='full-width center-flex' style={{ minHeight: '50vh' }}><Spin size="large" className="dark-spinner card-content-spinner" style={{ marginTop: '60px', textAlign: 'center' }} /></div>;
    } else if (reportExist && !reportV1) {
        reportContent = <ReportContainer>
            {isAdmin && (
                <AdminHeader {...{ codeHeader, disableContinueHeader, handleInputChange, reportSearchClickHandler }} />
            )}
            <div className='viewBoxWidth'>
            <ReportTopSection reportData={reportData} isETypeKit={isETypeKit} kitType={kitType} />

            {SHOW_MID_SECTION_KITS.includes(kitType) && (
              <ReportMidSection {...{reportData, recommendedProduct}} />)
            }

            <ReportCollapsibleSections
              {...{reportData, bundle, conciergeCare, primarySerumDesc, secondarySerumDesc, isAdmin, isETypeKit,kitType}} />

            <div className={style.boxMargin}
                 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                <Text className="note-2 text-center font-Mono-medium">
                    *These statements have not been evaluated by the Food and Drug Administration. The product is not
                    intended to diagnose, treat, cure or prevent any disease.
                </Text>
            </div>

          {isETypeKit ? <SampleReportDetailsContainer/> : ''}

            {isAdmin &&
                (<AdminFooter {...{code, disableContinue, handleInputChange, reportSearchClickHandler}} />)}
        </div>
        </ReportContainer>
    } else if (reportExist && reportV1) {
        reportContent = <ReportContainerV1 codeHeader={codeHeader} handleInputChange={handleInputChange} disableContinueHeader={disableContinueHeader} reportSearchClickHandler={reportSearchClickHandler} isAdmin={isAdmin} adminComponent={<AdminFooter {...{ code, disableContinue, handleInputChange, reportSearchClickHandler }} />}>
            <ReportV1 reportData={reportData} isETypeKit={isETypeKit} kitType={kitType} reportPlan={reportPlan} isAdmin={isAdmin} getReportData={getReportData} />
        </ReportContainerV1>
    }
    else {
        reportContent = <>
            <ReportContainer>
                {isAdmin && (
                    <AdminHeader {...{ codeHeader, disableContinueHeader, handleInputChange, reportSearchClickHandler }} />
                )}
                <ReportUnavailable {...{ isAdmin, user, customKitcode, errorMessage, errorStatus }} />
            </ReportContainer>
        </>
    }

    return reportContent
}
export default Report;


const ReportContainer = ({ children }) => {
    return (
        <>
            <Components.Header darkHeader={true} reportLayout={false} />
            <section className="section">
                <div className={"reportContainer"}>
                    <div className={style.container}>
                        {children}
                    </div>
                </div>
            </section>
            <Components.Footer />
        </>
    )
}

const ReportContainerV1 = ({ children, isAdmin, adminComponent, codeHeader, handleInputChange, disableContinueHeader, reportSearchClickHandler }) => {
    return (
        <>
            <PHCartHeader isAdmin={isAdmin} codeHeader={codeHeader} handleInputChange={handleInputChange} disableContinueHeader={disableContinueHeader} reportSearchClickHandler={reportSearchClickHandler} />
        <div className='bg-night'>
            <section className="section">
                    <div className="reportContainer1">
                        <div className={`${style.container} ${style.reportv1} bg-night`} >
                        {children}
                            <div className={`${style.thankyouContainer} show-mobile-hide-desktop`}>
                                <div>
                                    <span className={style.retestText}>Retest in 6 months</span>
                                </div>
                                <div>
                                    <span className={style.thankyouText}>Thank You!</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ maxWidth: '1440px', margin: 'auto' }}>
                        {isAdmin && (adminComponent)}
                    </div>

            </section >

        </div >
        </>
    )
}