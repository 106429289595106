import { Button } from 'antd'
import style from './queButtons.module.scss'
import { nextNavigate, prevNavigate, validateData } from '@ph/common/utils/quizUtils';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { updateQuestionNumber, updateSessionId, updateUserInfoDirty, resetQuizData, updateOtherInfoDirty } from '@ph/common/store/quizData.reducer';
import { useState } from 'react';
import { postQuizData, postPartialQuizData } from '@ph/subject/src/api_services/api';
import { handleErrorMsg } from '@ph/common/global/Helpers';
import { NO_ISSUES_TEXT } from '@ph/common/global/Constants';

function QueButtons({ currentQue, first = false, final = false, disabled = false, onClick = null, prevQue = null
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const state = useSelector(state => state.quizData)
  const ans = state[currentQue.queId]
  const [loading, setLoading] = useState(false)

  // const quizData = useSelector(state => state.quizData);

  // function replaceNoIssueWithEmptyArray(obj) {
  //   // Iterate through all keys in the object
  //   let copyObj = { ...obj }
  //   for (let key in copyObj) {
  //     // Check if the value is an array and contains 'No issue'
  //     if (Array.isArray(copyObj[key]) && copyObj[key].length === 1 && copyObj[key][0] === NO_ISSUES_TEXT) {
  //       copyObj[key] = []; // Set the value to an empty array
  //     }
  //   }
  //   return copyObj
  // }

  function updateBackURL(customURL) {
    // Check if the browser supports the History API
    if (window.history && window.history.pushState) {
      // Push the custom URL to the browser history
      window.history.pushState({}, '', customURL);

      // Override the back button behavior
      window.addEventListener('popstate', function () {
        window.location.href = customURL;
      });
    } else {
      // Fallback for browsers that don't support the History API
      window.location.hash = customURL;
    }
  }


  const handleNextButton = () => {
    if (!disabled) {
      if (final) {
        handleSubmitQuiz()
      } else {
        const nextPage = nextNavigate(currentQue)

        if (state?.otherDataDirty) {
          const updatedState = {
            ...state, currentQuestionNumber: state.currentQuestionNumber + 1
          }

          const data = {
            "quiz_data": updatedState,
            "quiz_type": 'recommendations',
            "quiz_version": '1.0',
            "session_id": state?.currentSessionId,
            "user_info_dirty": state?.userInfoDirty
          }
          postPartialQuizData(data).then(res => {
            if (res && res.data) {
              if (res.data) {
                if (res?.data?.session_id) {
                  dispatch(updateSessionId(res.data.session_id))
                  dispatch(updateUserInfoDirty(false))
                  dispatch(updateOtherInfoDirty(false))
                }
              }
            }

          }).catch(error => handleErrorMsg(error, 'Something went wrong!'))
            .finally(() => {
              setLoading(false)
            })
        }
        dispatch(updateQuestionNumber(nextPage?.order))
        // navigate(`/quiz/${nextPage?.route}`)

      }

    } else {
      if (onClick) {
        onClick()
      }
    }
  }

  const handleSubmitQuiz = () => {
    if (state) {
      const data = {
        "quiz_data": state,
        "quiz_type": 'recommendations',
        "quiz_version": '1.0',
        "session_id": state?.currentSessionId
      }
      setLoading(true)
      postQuizData(data).then(res => {
        if (res && res.data) {
          if (res.data) {
            console.log(res.data)
            if (res.data?.quiz_submission_id) {
              const quizId = res.data.session_id || res.data?.quiz_submission_id
              dispatch(resetQuizData())
              updateBackURL('/clarity-quiz')
              navigate(`/clarity-quiz/result/${quizId}`)
            }
          }
        }
        setLoading(false)
      }).catch(error => handleErrorMsg(error, 'Something went wrong!'))
        .finally(() => setLoading(false))
    }
  }

  return (
    <div className={style.QueButtonsWrapper}>
      <Button type="primary" className={`${style.QueButton} ${style.prev}`}
        onClick={() => {
          if (!prevQue) {
            prevQue = currentQue
          }
          const prevPage = prevNavigate(prevQue)
          dispatch(updateQuestionNumber(prevPage?.order))
        }}
        disabled={first}
      >
        <svg width="26" height="41" viewBox="0 0 26 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.292892 21.6661C-0.0976315 21.2756 -0.0976314 20.6424 0.292893 20.2519L6.65686 13.8879C7.04738 13.4974 7.68054 13.4974 8.07107 13.8879C8.46159 14.2784 8.46159 14.9116 8.07107 15.3021L2.41421 20.959L8.07107 26.6158C8.46159 27.0064 8.46159 27.6395 8.07107 28.0301C7.68054 28.4206 7.04738 28.4206 6.65685 28.0301L0.292892 21.6661ZM26 21.959L1 21.959L1 19.959L26 19.959L26 21.959Z" fill="#2D2926" />
        </svg>
      </Button>
      <Button type="primary" className={`${style.QueButton} ${style.next} ${final ? style.final : ''}`} onClick={handleNextButton} loading={loading}>
        {final ? 'You’re all done!' : 'Next'}
        <svg width="26" height="41" viewBox="0 0 26 41" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)', marginLeft: '12px' }}>
          <path d="M0.292892 21.6661C-0.0976315 21.2756 -0.0976314 20.6424 0.292893 20.2519L6.65686 13.8879C7.04738 13.4974 7.68054 13.4974 8.07107 13.8879C8.46159 14.2784 8.46159 14.9116 8.07107 15.3021L2.41421 20.959L8.07107 26.6158C8.46159 27.0064 8.46159 27.6395 8.07107 28.0301C7.68054 28.4206 7.04738 28.4206 6.65685 28.0301L0.292892 21.6661ZM26 21.959L1 21.959L1 19.959L26 19.959L26 21.959Z" fill="#FFFFFF" />
        </svg></Button>
    </div>
  )
}

export default QueButtons
