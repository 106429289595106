import React, { useEffect, useState } from 'react'
import style from './quizCart.module.scss'
import { quiz_close, button_arrow, read_more, shopify_icon_light } from "@ph/common/assets/index";
import { Drawer, Button, Typography, Grid, Collapse } from 'antd'
import { useQuizCart } from '@ph/common/context/QuizCartContext';
import { convertPrice } from '@ph/common/global/Helpers';
import MD03UpgradeModal from '../../Report/ReportContainer/MD03UpgradeModal';
import { QUIZ_PLAN_MODAL_DETAILS, MD03_LABEL } from '@ph/common/constants/QuizConstants';
import { getPrioritySubtitle, QUIZ_PLAN_MODAL_TYPES, getProductName } from '@ph/common/constants/QuizConstants';
import { CART_META_DATA } from '@ph/common/constants/QuizConstants';

const { Text } = Typography

const { useBreakpoint } = Grid

function QuizCart() {
    const [openCart, setOpenCart] = useState(false)
    const [total, setTotal] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [loading, setLoading] = useState(false)
    const { products, quizModal, quizModalType, updateMD03Priority, updateQuizModal, removeLastMd03Product, md03Products, isMd03Member, isMD03Product, md03MainProduct } = useQuizCart()
    const screens = useBreakpoint();
    const isMobile = screens.xs
    const handleDrawerClick = () => {
        setOpenCart(!openCart)
    }
   

    const calculateTotal = () => {
        let sum = 0
        let discount = 0
        if (products && products.length > 0) {
            products.map(product => {
                if (isMd03Member) {
                    if (isMD03Product(product)) {
                        if (product?.variant_id === md03MainProduct?.variant_id) {
                            sum += parseFloat(product?.price)
                            discount += parseFloat(product?.compare_at_price) + parseFloat(product?.price) + 95
                        }
                        else {
                            sum += parseFloat(product?.md03_discount_variant_price)
                            discount += parseFloat(product?.compare_at_price) + parseFloat(product?.md03_discount_variant_price) + 95

                        }

                    } else {
                        sum += parseFloat(product?.price)
                        discount += parseFloat(product?.compare_at_price)
                    }
                } else {
                    sum += parseFloat(product?.ala_cart_price)
                }
            })

            if (isMd03Member) {
                // Add price of daily reset duo 
                discount += parseFloat(150)
            }
        }
        return { discount, sum }
    }

    function createCartLink(domain, products) {
        const baseUrl = `https://${domain}/cart/clear?return_to=/cart/add?`;
        return `${baseUrl}${getVariantUrl(products)}return_to=/checkout`;
    }

    const handleAddToCart = () => {
        setLoading(true)
        const cartLink = createCartLink(process.env.REACT_APP_REPORT_SHOPIFY_DOMAIN, products)
        window.location.href = cartLink;

    }

    const getVariantUrl = (productArr) => {
        const variant_id_key = isMd03Member ? 'variant_id' : 'ala_cart_variant_id'
        const selling_plan_key = isMd03Member ? 'selling_plan_id' : 'ala_cart_selling_plan_id'
        const discount_variant_key = 'md03_discount_variant'
        let url = ''
        productArr.forEach(variant => {
            if (isMD03Product(variant) && md03MainProduct.variant_id !== variant.variant_id) {
                url += `items[][id]=${variant[discount_variant_key]}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
            } else if (variant[selling_plan_key]) {
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
            } else {
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26`
            }
        })
        return url
    }

    const getModalSubtitle = () => {
        if (quizModalType === QUIZ_PLAN_MODAL_TYPES.PRIORITY) {
            // md03MainProduct
            const md03ProductIds = new Set(md03Products.map(product => product.variant_id));
            const remainingMd03Product = products.filter(item => md03ProductIds.has(item.variant_id) && item.variant_id != md03MainProduct.variant_id)
            const remainingFirstProduct = remainingMd03Product[0]
            return getPrioritySubtitle(md03MainProduct, remainingFirstProduct)

        } else {
            return QUIZ_PLAN_MODAL_DETAILS[quizModalType]?.subTitle
        }
    }

    const handleModalAction = () => {
        if (quizModalType === QUIZ_PLAN_MODAL_TYPES.PRIORITY) {
            updateMD03Priority(md03MainProduct.variant_id)
        } else {
            removeLastMd03Product()
        }
    }


    useEffect(() => {
        const { discount, sum } = calculateTotal(products)
        setTotal(sum)
        setDiscount(discount)
    }, [products])
    return (
        <>
            <div className={style.cartIcon} onClick={handleDrawerClick}>
                {!openCart ? (
                    <img src={shopify_icon_light} alt='cart' className={style.icon} />
                ) : (<svg className={`${style.icon} ${style.arrowIcon}`} width="21" height="27" viewBox="0 0 21 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.34744 26.7358C8.69488 27.0976 9.27394 27.0976 9.62138 26.6755L20.7394 14.1331C21.0869 13.7713 21.0869 13.2287 20.7394 12.8669L9.62138 0.324545C9.27394 -0.0975513 8.69488 -0.0975513 8.34744 0.264246C7.94209 0.626043 7.94209 1.22904 8.28953 1.59084L18.8285 13.5302L8.28953 25.4092C8.11581 25.5901 8 25.8313 8 26.0725C8 26.3137 8.11581 26.5549 8.34744 26.7358Z" fill="white" />
                    <path d="M0.374165 26.7358C0.74833 27.0976 1.37194 27.0976 1.7461 26.6755L13.7194 14.1331C14.0935 13.7713 14.0935 13.2287 13.7194 12.8669L1.7461 0.324545C1.37194 -0.0975513 0.74833 -0.0975513 0.374165 0.264246C-0.0623608 0.626043 -0.0623608 1.22904 0.311804 1.59084L11.6615 13.5302L0.311804 25.4092C0.124722 25.5901 0 25.8313 0 26.0725C0 26.3137 0.124722 26.5549 0.374165 26.7358Z" fill="white" />
                </svg>
                )}
            </div>
            <Drawer
                open={openCart}
                onClose={handleDrawerClick}
                width={'680px'}
                height={'100vh'}
                placement={isMobile ? "bottom" : 'right'}
                closable={true}
                className={style.cartDrawer}
                closeIcon={<img src={quiz_close} alt='X' />}
            >
                <div className={style.cartContainer}>
                    <div className={style.cartProductWrap}>
                        <div className={`${style.cartItem} ${style.first}`}>
                            <Text className={style.cartTitle}>
                                PRODUCT RECOMMENDATIONS SUMMARY
                            </Text>
                        </div>
                        {products.map(item => (isMD03Product(item) ? <Md03CartProduct product={item} key={item.variant_id} md03MainProduct={md03MainProduct} /> :
                            <div className={style.cartItem} key={item.variant_id}>
                                <Text className={`${style.itemTitle} ${style.bold}`}>
                                    {item?.product_name}
                                </Text>
                                <div className={style.priceContainer}>
                                    <Text className={`${style.discountPrice} ${style.bold}`}>
                                        {`$${convertPrice(item?.compare_at_price)}`}
                                    </Text>
                                    <Text className={`${style.itemPrice} ${style.bold}`}>
                                        {`$${isMd03Member ? convertPrice(item?.price) : convertPrice(item?.ala_cart_price)}/mo`}
                                    </Text>
                                </div>
                            </div>
                        ))}
                        {isMd03Member && (
                            <div className={style.cartItem}>
                                <Text className={`${style.itemTitle} ${style.bold}`}>
                                    Daily Reset Duo (in month 2)
                                </Text>
                                <div className={style.priceContainer}>
                                    <Text className={`${style.discountPrice} ${style.bold}`}>
                                        {`$${convertPrice(150)}`}
                                    </Text>
                                    <Text className={`${style.itemPrice} ${style.bold}`}>
                                        INCLUDED
                                    </Text>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <div className={style.total}>
                        <Text className={style.itemTitle}>
                            TOTAL
                        </Text>
                        <Text className={style.itemPrice}>
                            ${convertPrice(total)}/mo
                        </Text>
                    </div>
                    <div className={style.subTotal}>
                        <div className={style.line}></div>
                        <div className={style.disclaimer}>

                            <div className={style.text}>
                                <span className={style.icon}>
                                    ■
                                </span>
                                All protocols require a 3-mo minimum commitment to complete the Introductory Experience. After you purchase, you will receive a short on-boarding call before your first shipment is sent.
                            </div>
                        </div>
                    </div> */}
                    <div>
                    <div className={style.total}>
                        <Text className={style.itemTitle}>
                            Total
                        </Text>

                        <Text className={style.itemPrice}>
                                <Text className={style.discountPrice}>
                                    ${discount}/mo
                                </Text>
                            <Text className={style.itemPrice}>
                                ${total}/mo
                            </Text>
                        </Text>
                    </div>
                    <div className={style.subTotal}>
                        <div className={style.line}></div>
                        <div className={style.disclaimer}>

                            <div className={style.text}>
                                <span className={style.icon}>
                                    ■
                                </span>
                                All protocols require a 3-mo minimum commitment to complete the Introductory Experience. After you purchase, you will receive a short on-boarding call before your first shipment is sent.
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className={style.cartButtonContainer}>
                    <Button type="primary" className={style.addButton} onClick={handleAddToCart} loading={loading}> Proceed To Checkout <img src={button_arrow} className='ph-btn-arrow' /></Button>
                </div>
            </Drawer>
            <MD03UpgradeModal
                openModal={quizModal}
                setOpenModal={updateQuizModal}
                title={QUIZ_PLAN_MODAL_DETAILS[quizModalType]?.title}
                subTitle={getModalSubtitle()}
                cancelText={QUIZ_PLAN_MODAL_DETAILS[quizModalType]?.cancelText}
                successText={QUIZ_PLAN_MODAL_DETAILS[quizModalType]?.successText}
                onCancel={handleModalAction}
                onSuccess={() => updateQuizModal(false)}
            />
            {!openCart && (

                <div className={style.floatButton}>
                    <div className={style.cartButtonContainer}>
                        <Button type="primary" className={style.addButton} onClick={handleDrawerClick}> Review Order <img src={button_arrow} className='ph-btn-arrow' /></Button>
                    </div>
                </div>
            )}
        </>
    )
}

export default QuizCart

const Md03CartProduct = ({ product, md03MainProduct }) => {

    const [isActive, setIsActive] = useState(false)

    const customExpandIcon = ({ isActive }) => {
        setIsActive(isActive)
        return <></>
    };



    return (
        <>
            <Collapse
                size="small"
                expandIcon={customExpandIcon}
                items={[{
                    key: product.product_title, label: <div className={`${style.cartItem} ${style.md03Item}`}>
                        <Text className={`${style.itemTitle} ${style.bold}`}>
                            {getProductName(product.product_name)}

                            {isActive ? (
                                <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.4375 9.56201L8.71851 1.84302L0.999512 9.56201" stroke="#2E5ACC" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            ) : (
                                <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1.71899L8.719 9.43799L16.438 1.71899" stroke="#2E5ACC" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            )}


                        </Text>

                        <div className={style.priceContainer}>
                            <Text className={`${style.discountPrice} ${style.bold}`}>
                                {`$${convertPrice(parseFloat(product?.compare_at_price) + parseFloat(product?.variant_id === md03MainProduct?.variant_id ? product?.price : product?.md03_discount_variant_price) + 95)}`}
                            </Text>
                            <Text className={`${style.itemPrice} ${style.bold}`}>
                                {product?.variant_id === md03MainProduct?.variant_id ? `$${convertPrice(product?.price)}/mo` : `$${convertPrice(product?.md03_discount_variant_price)}/mo`}
                            </Text>
                        </div>


                    </div>, children: <>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={`${style.subItemTitle}`}>
                                {CART_META_DATA[product.variant_id]?.serum}
                            </Text>
                        </div>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={style.subItemTitle}>
                                {CART_META_DATA[product.variant_id]?.kitName}

                                <Text className={`${style.redDiscountPrice}`}>
                                    {`$${convertPrice(product?.compare_at_price)}`}
                                </Text>
                                <Text className={style.redItemPrice}>
                                    Included
                                </Text>
                            </Text>
                        </div>

                        <div className={`${style.cartItem} ${style.subCartItem}`}>

                            <Text className={style.subItemTitle}>
                                {CART_META_DATA[product.variant_id]?.reportName}

                                <Text className={style.redItemPrice}>
                                    Included
                                </Text>
                            </Text>

                        </div>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>

                            <Text className={style.subItemTitle}>
                                {CART_META_DATA[product.variant_id]?.consult}
                                <Text className={`${style.redDiscountPrice}`}>
                                    {`$95/mo`}
                                </Text>
                                <Text className={style.redItemPrice}>
                                    Included
                                </Text>
                            </Text>
                        </div>

                    </>
                }]}
                className={style.customCollapse}
            />
        </>
    )
}


