import { useState } from 'react';
import style from "./PHProtocol.module.scss";
import { Typography } from 'antd';
import { read_more } from '@ph/common/assets/index';

const { Paragraph } = Typography;

function ProtocolReadMoreDescContainer({ description, rowCount = 4, productId = 1, productName = '', className = '' }) {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [fullDescriptionKey, setFullDescriptionKey] = useState(productId);

    const markup = typeof description === 'object' && description.length
        ? description.flatMap((part, index) =>
            index === description.length - 1
                ? part
                : [part, <b key={index}>{productName}</b>]
        )
        : [description];

    const toggleFullProdDescHandler = (showOrHide) => {
        if (typeof showOrHide === 'boolean') {
            setShowFullDescription(showOrHide);
        } else {
            setShowFullDescription(!showFullDescription);
        }
        setFullDescriptionKey(fullDescriptionKey + (!showFullDescription ? 0 : 1));
    };

    return (
        <>
            <div key={fullDescriptionKey} className={`${style.productDesc} ${className}`}>
                <Paragraph className={`${style.productDesc} ${className}`}
                    ellipsis={{
                        rows: rowCount,
                        expandable: true,
                        symbol: <img src={read_more} className='ant-typography-expand hand-cursor' style={{ display: 'inline-block', marginLeft: '0px' }} />,
                        onExpand: (event) => {
                            event.stopPropagation()
                            toggleFullProdDescHandler(true)
                        }
                    }}
                >
                    {description}
                </Paragraph>
                {showFullDescription &&
                    <img src={read_more} className='ant-typography-expand hand-cursor'
                        style={{
                            display: 'inline', marginLeft: '6px', marginTop: '-3px', transform: 'rotate(180deg)',
                            width: '12px'
                        }}
                        onClick={() => toggleFullProdDescHandler(false)}
                    />
                }
            </div>

        </>
    );
}


export default ProtocolReadMoreDescContainer;