import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { scrollToContainer } from '@ph/common/global/Helpers'
import { useEffect, useMemo } from 'react'
import QuizHallmarks from '../QuizHallmarks/QuizHallmarks'
import { ISSUES_PRIORITY } from '@ph/common/constants/QuizConstants'
import { NO_ISSUES_TEXT } from '@ph/common/global/Constants'

// Validation schema
const validationSchema = Yup.object().shape({
  issuesPriority: Yup.array().min(1, 'At least one option must be selected'), // Ensure at least one issue is selected
});


function PHQIssuesPriority() {
  const Que = getQuestionByQueId(queConstants.phq_issues_priority)
  const ans = useSelector(state => state.quizData[queConstants.phq_issues_priority])

  const faceAreas = useSelector(state => state.quizData[queConstants.phq_face_area])
  const blemishAreas = useSelector(state => state.quizData[queConstants.phq_body_blemish_area])
  const odorAreas = useSelector(state => state.quizData[queConstants.phq_body_odor_area])
  const scalpAreas = useSelector(state => state.quizData[queConstants.phq_scalp_issues]) || []

  const dispatch = useDispatch();


  // Using React Hook Form
  const { control, handleSubmit, setValue, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      issuesPriority: ans || [], // Default to existing answers or an empty array
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };


  const items = useMemo(() => {
    const areaTypes = [
      { areas: faceAreas, title: 'Face' },
      { areas: scalpAreas, title: 'Scalp' },
      { areas: blemishAreas, title: 'Body Blemish' },
      { areas: odorAreas, title: 'Body Odor' }
    ];

    const filteredAreas = areaTypes.map(type => ({
      ...type,
      filtered: type.areas.filter(area => area !== NO_ISSUES_TEXT)
    }));

    let allIssues = ISSUES_PRIORITY.filter(issue =>
      filteredAreas.some(area => area.filtered.length > 0 && area.title === issue.title)
    );

    const existingAns = ans || []
    const allIssueTitle = allIssues.map(issue => issue.title);
    const updateSelectedIssue = existingAns.filter(area => allIssueTitle.includes(area));

    // Update value of issue priority if changed. Since user can go back and update it
    dispatch(updateQuizData({
      queID: queConstants.phq_issues_priority,
      ans: updateSelectedIssue
    }));
    setValue('issuesPriority', updateSelectedIssue);

    return allIssues;
  }, [faceAreas, blemishAreas, odorAreas, scalpAreas]);


  useEffect(() => {
    if (errors?.issuesPriority) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.issuesPriority])


  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>Based on your selected issues, <br className='show-desktop-hide-mobile' />
              which are you most eager to tackle?</div>
            <div className='PHQMessage'>■ SELECT THE ISSUES BASED ON YOUR PRIORITY</div>
            <div className='PHQSpacer'></div>

            <Controller
              name="issuesPriority"
              control={control}
              render={({ field }) => (
                <QuizHallmarks
                  values={field.value}
                  items={items}
                  onChange={(value) => {
                    field.onChange(value); // Update form state
                    dispatch(updateQuizData({ queID: queConstants.phq_issues_priority, ans: value })); // Update Redux state
                  }}
                  large={true}
                />
              )}
            />

            <p className="PHQErrorMessage" id="errorMessage">
              {errors.issuesPriority && (
                <span >{errors.issuesPriority.message}</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <QueButtons currentQue={Que} final={true} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  )
}

export default PHQIssuesPriority