import { useEffect, useState } from "react";
import style from "./quizCartHeader.module.scss";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useScrollingUp from '../UseScrollingUp';
import { useNavigate } from 'react-router-dom';
import {  restart_icon, quiz_close, button_arrow } from '@ph/common/assets/index';
import { resetQuizData } from '@ph/common/store/quizData.reducer'
import MD03UpgradeModal from "../../Report/ReportContainer/MD03UpgradeModal";
import { useQuizCart } from "../../../context/QuizCartContext";
import PhButton from "../PhButton/PhButton";
import { Button } from "antd";


const QuizCartHeader = (props) => {
    const scrolled = useScrollingUp();
    let location = useLocation();
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [currentPath, setcurrentPath] = useState(location.pathname);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const user = useSelector((state) => state.userData.userData);
    const [openModal, setOpenModal] = useState(false)
    const [openExitModal, setExitModal] = useState(false)

   const { products, quizModal, quizModalType, updateMD03Priority, updateQuizModal, removeLastMd03Product, md03Products, isMd03Member, isMD03Product, md03MainProduct } = useQuizCart()

    useEffect(() => {
        setcurrentPath(location.pathname);
    }, [location]);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    function handleGoBack() {
        setExitModal(false)
        window.open(process.env.REACT_APP_QUIZ_SHOPIFY_LINK, '_self')
    }

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('#desktopHeader');
        if (!header) return;
        const scrollTop = window.scrollY;
        if (header && header.classList) {
            scrollTop >= 250 ?
                header.classList.add(style.headerContainer) :
                header.classList.remove(style.headerContainer);
        }
    };

    const handleStartOver = () => {
        setOpenModal(false)
        dispatch(resetQuizData())
        navigate('/quiz')
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleOpenExitModal = () => {
        setExitModal(true)
    }
    function createCartLink(domain, products) {
        const baseUrl = `https://${domain}/cart/clear?return_to=/cart/add?`;
        return `${baseUrl}${getVariantUrl(products)}return_to=/checkout`;
    }

    const handleAddToCart = () => {
        const cartLink = createCartLink(process.env.REACT_APP_REPORT_SHOPIFY_DOMAIN, products)
        window.location.href = cartLink;

    }

    const getVariantUrl = (productArr) => {
        const variant_id_key = isMd03Member ? 'variant_id' : 'ala_cart_variant_id'
        const selling_plan_key = isMd03Member ? 'selling_plan_id' : 'ala_cart_selling_plan_id'
        const discount_variant_key = 'md03_discount_variant'
        let url = ''
        productArr.forEach(variant => {
            if (isMD03Product(variant) && md03MainProduct.variant_id !== variant.variant_id) {
                url += `items[][id]=${variant[discount_variant_key]}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
            } else if (variant[selling_plan_key]) {
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
            } else {
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26`
            }
        })
        return url
    }

    return (
        <>
            <header className={scrolled ? style.stickyHeader + " " + style.headerContainer : style.headerContainer}>
                <div className={`${style.quizBox} viewBoxWidth smallViewBoxWidth`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
                    <div className={`${style.firstContainer} hand-cursor`} onClick={handleOpenModal}>
                        <img src={restart_icon} className={style.restartIcon} alt='' />
                        <span className={style.text}>Start Over</span>
                    </div>
                   
                    <div className={style.buttonSection}>
                        <div className={`${style.productCount} show-desktop-hide-mobile`}>
                            {products.length} {products.length < 2 ? 'Product' : 'Products'} Added
                        </div>
        
                        <div className={`${style.floatButton} show-desktop-hide-mobile`}>
                            <div className={style.cartButtonContainer}>
                                <Button type="primary" className={style.addButton} onClick={handleAddToCart}> Proceed To Checkout <img src={button_arrow} className='ph-btn-arrow' /></Button>
                            </div>
                        </div>

                        <img src={quiz_close} className={style.closeIcon} alt='' onClick={handleOpenExitModal} />
                       
                    </div>
                </div>
                <MD03UpgradeModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    title={'Starting Over?'}
                    subTitle={'Are you sure you want to start over? All of your responses will be cleared if you proceed.'}
                    cancelText={'Start Over'}
                    successText={'Continue Quiz'}
                    onCancel={() => handleStartOver(false)}
                    onSuccess={() => setOpenModal(false)}
                />
                <MD03UpgradeModal
                    openModal={openExitModal}
                    setOpenModal={setExitModal}
                    title={'Uh Oh!'}
                    subTitle={'You are doing good. We would suggest you complete the quiz to get the personalized product recommendations for your skin.'}
                    cancelText={'Exit Quiz'}
                    successText={'Continue Quiz'}
                    onSuccess={() => setExitModal(false)}
                    onCancel={() => handleGoBack()}
                />
            </header>
        </>
    );
};

export default QuizCartHeader;

