
import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import QuizSlider from '../QuizSlider/QuizSlider'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { scrollToContainer } from '@ph/common/global/Helpers'


// Validation schema
const validationSchema = Yup.object().shape({
  agingProcess: Yup.number().min(1, 'Please select a value on the scale').max(10).required('Please rate the gentleness of your cleanser'), // Ensure a value is selected
});

function PHQAgingProcess() {
  const Que = getQuestionByQueId(queConstants.phq_aging_process)
  const agingProcessAns = useSelector(state => state.quizData[queConstants.phq_aging_process])
  const dispatch = useDispatch();

  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      agingProcess: agingProcessAns || 0, // Default to existing answer or 0
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.agingProcess) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.agingProcess])



  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>How do you feel about your skin aging?</div>
            <div className='PHQMessage'>■ click on the scale below</div>
            <div className='PHQSpacer'></div>

            <Controller
              name="agingProcess"
              control={control}
              render={({ field }) => (
                <QuizSlider
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value); // Update form state
                    dispatch(updateQuizData({ queID: queConstants.phq_aging_process, ans: value }))
                  }}
                  leftText='I’m all in for the aging journey'
                  rightText='I’d like to reverse my skin aging as much as possible'
                  domId={'aging'}
                  gradient='linear-gradient(90deg, #C46A5A 0%, #2E5ACC 97.5%)'
                />
              )}
            />
            <div className='sliderButton'>
            </div>
            <p className="PHQErrorMessage sliderError" id="errorMessage">
              {errors?.agingProcess && (
                <span>{errors?.agingProcess.message}<br /></span>
              )}
            </p>
          </div>
        </div>
      </div>
      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  )
}

export default PHQAgingProcess