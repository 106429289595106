import { createSlice } from "@reduxjs/toolkit";
import { queConstants } from "@ph/common/constants/QuizConstants";


const initialState = {
    currentQuestionNumber: 1,
    currentSessionId: '',
    userInfoDirty: false,
    otherDataDirty: false,
    startOverClicked: false,
    [queConstants.phq_name]: '',
    [queConstants.phq_email]: '',
    [queConstants.phq_age]: '',
    [queConstants.phq_zipcode]: '',
    [queConstants.phq_current_career]: '',
    [queConstants.phq_workstress]: null,
    [queConstants.phq_gender]: null,
    [queConstants.phq_ethnicity]: null,
    [queConstants.phq_current_career]: null,
    [queConstants.phq_hallmarks]: [],
    [queConstants.phq_face_area]: [],
    [queConstants.phq_face_issues]: [],
    [queConstants.phq_body_blemish_area]: [],
    [queConstants.phq_body_issues]: [],
    [queConstants.phq_body_odor_area]: [],
    // [queConstants.phq_scalp_area]: [],
    [queConstants.phq_scalp_issues]: [],
    [queConstants.phq_cleanser_gentleness]: -1,
    [queConstants.phq_skin_moisturizer]: -1,
    [queConstants.phq_doctor_skincare]: null,
    [queConstants.phq_aging_process]: 0,
    [queConstants.phq_skincare_adventure]: null,
    [queConstants.phq_skincare_curiosity]: null,
    [queConstants.phq_issues_priority]: []

}

const quizDataSlice = createSlice({
    name: "quizData",
    initialState,
    reducers: {
        updateQuizData(state, action) {
            if (action.payload) {
                state[action.payload.queID] = action.payload.ans;
                // Update dirty state for all
                state.otherDataDirty = true

            }
        },
        updateQuestionNumber(state, action) {
            if (action.payload) {
                state.currentQuestionNumber = action.payload
            }
        },
        updateSessionId(state, action) {
            return {
                ...state,
                currentSessionId: action.payload
            };
        },
        updateUserInfoDirty(state, action) {
            return {
                ...state,
                userInfoDirty: action.payload,
            };
        },
        updateOtherInfoDirty(state, action) {
            return {
                ...state,
                otherDataDirty: action.payload
            };
        },
        loadPrevAnswers(state, action) {
            return { ...initialState, ...action.payload };
        },
        resetQuizDataAfterStartOver(state) {
            return {
                ...initialState,
                startOverClicked: true
            }
        },
        resetQuizData(state) {
            return initialState
        }
    },
});

export const { updateQuizData, updateQuestionNumber, resetQuizDataAfterStartOver, resetQuizData, updateSessionId, updateUserInfoDirty, loadPrevAnswers, updateOtherInfoDirty } = quizDataSlice.actions;

export default quizDataSlice;
