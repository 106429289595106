import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import ButtonOptions from './QuiZButtonOption/index'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react'
import { scrollToContainer } from '@ph/common/global/Helpers'

// Validation schema
const validationSchema = Yup.object().shape({
  bodyIssues: Yup.array().min(1, 'At least one option must be selected'), // Ensure at least one issue is selected
});

// import Components from "@ph/common/components";
function PHQBodyIssues() {
  const Que = getQuestionByQueId(queConstants.phq_body_issues)
  const bodyIssuesAns = useSelector(state => state.quizData[queConstants.phq_body_issues])
  const dispatch = useDispatch();

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      bodyIssues: bodyIssuesAns || [], // Default to existing answers or an empty array
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.bodyIssues) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.bodyIssues])

  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>Tell us about any skin issues you’re managing on your <span className='font-Neue-medium'>body</span>. </div>
            <div className='PHQMessage'>■ choose all that apply</div>
            <div className='PHQSpacer'></div>
            <div className={`${style.midButtonOption}`}>
              <Controller
                name="bodyIssues"
                control={control}
                render={({ field }) => (
                  <ButtonOptions
                    question={`What are your current skin concerns?`}
                    options={['Fungal Acne', 'Cystic/Hormonal Acne', 'Eczema/Dry Skin', 'Redness/Inflammation', 'Dark Spots', 'Hidradenitis Suppuritiva']}
                    values={field.value}
                    onChange={(value) => {
                      field.onChange(value); // Update form state
                      dispatch(updateQuizData({ queID: queConstants.phq_body_issues, ans: value })); // Update Redux state
                    }}
                    desktopCol={2}
                    otherAllowed={true}
                  />
                )}
              />
            </div>
            <p className="PHQErrorMessage" id="errorMessage">
              {errors.bodyIssues && (
                <span >{errors.bodyIssues.message}</span>
              )}
            </p>

          </div>
        </div>
      </div>
      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  )
}

export default PHQBodyIssues