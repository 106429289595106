import React from 'react';
import { Select } from 'antd';
import style from "./CustomSelect.module.scss"


const onSearch = (value) => {
    // console.log('search:', value);
};

const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const CustomSelect = ({ selected, placeholder, onChangeHandler, options, showSearch = true, dark = false, disabled = false }) => (
    <Select
        showSearch={showSearch}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChangeHandler}
        onSearch={onSearch}
        filterOption={filterOption}
        options={options}
        value={selected}
        className={`phSelect ${dark ? "dark" : ''}`}
        disabled={disabled}
    />
);
export default CustomSelect;