import React from 'react'
import { Row, Col } from 'antd'
import style from './quizHallmarks.module.scss'

function QuizHallmarks({ values, onChange, items, large = false }) {

    const handleOnChange = (newValue) => {
        let valuesCopy = values
        if (!Array.isArray(values))
            valuesCopy = []

        if (newValue == null) return;

        const updatedValues = valuesCopy.includes(newValue)
            ? valuesCopy.filter(item => item !== newValue) // Remove the value if it exists
            : [...valuesCopy, newValue]; // Add the new value

        onChange(updatedValues);
    };


    const optionStyles = {
        '--length': items.length,
    };

    return (
        <div style={optionStyles}>
            <Row className={style.quizHallmarkContainer}>
                {items.map((item, index) => (
                    <Col
                        key={index}
                        xs={12}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={3}
                        className={style.grid}
                    >
                        <div
                            className={`${style.gridItem} ${index === 0 ? style.first : ''} ${values?.includes(item?.title) ? style.selected : ''}`}
                            onClick={() => handleOnChange(item?.title)}
                        >
                            <div className={`${style.icon} ${large ? style.large : ''}`}>
                                {item?.icon}
                            </div>
                            <div className={`${style.title} ${large ? style.large : ''}`}>
                                {item?.title}
                            </div>
                            {item?.very_short_description && (
                                <div className={style.subTitle}>
                                    {item?.very_short_description}
                                </div>
                            )}

                            {
                                values?.indexOf(item?.title) >= 0 && (
                                    <div className={style.label}> {values?.indexOf(item?.title) + 1} </div>
                                )
                            }
                        </div>  
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default QuizHallmarks