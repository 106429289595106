import { useEffect, useState } from 'react';
import ProtocolSilder from "./ProtocolSilder";
import { Row, Col, Spin } from 'antd';
import style from "./PHProtocol.module.scss";
import { useProtocolContext } from '@ph/subject/src/context/ProtocolContext';
import { PROTOCOL_FETCH } from '@ph/subject/src/api_services/Protocol/protocolFetch';
import RatingStars from '../Global/RatingStars/RatingStars';
import FrontRow from './FrontRow/FrontRow';


function PHProtocol() {

    const { protocolData, setVariantsData } = useProtocolContext()
    const [loading, setLoading] = useState(true)
    const [productData, setProductData] = useState({})
    const [productPrice, setProductPrice] = useState(protocolData?.product_data?.product_price)

    useEffect(() => {
        if (protocolData?.fetch_id) {
            setLoading(true)
            PROTOCOL_FETCH[protocolData?.fetch_id].onFetch().then(res => {
                setProductData(res)
            })
                .catch(error => console.error(error))
                .finally(() => setLoading(false))
        } else {
            setProductData(protocolData?.product_data)
        }
        if (protocolData?.fetch_id) {
            PROTOCOL_FETCH[protocolData?.price_fetch_id].onFetch().then(res => {
                setProductPrice(res.product_price)
            })
                .catch(error => console.error(error))
                .finally(() => setLoading(false))
        }
    }, [])

    if (loading) {
        return (
            <div className="text-center" style={{ padding: "40px" }}>
                <Spin size="large" className={`dark-spinner card-content-spinner`} />
            </div>
        );
    }
    else {
        return (
            <Row style={{ marginTop: '37.5px', width: '100vw', height: 'calc(100vh - 37.5px)', minHeight: '690px' }}>
                <Col xs={24} sm={24} md={24} lg={9} className={style.protocolContainer}>
                    <Row className={style.protocolDetail} gutter={16} >
                        <Col xs={24} sm={24} md={24} xl={24} className={style.headContainer} >
                            <h3 className={style.title} ><span className='font-NeueHGDP-light '>The</span> <span className='font-Neue-medium'>{productData?.product_title}</span></h3>
                            <div className={style.ratingContainer}>
                                <RatingStars />
                                <div className='body-txt-lg text-uv-blue'>Starting at <span className='font-Neue-medium'>${Number(productPrice)}/mo</span></div>
                            </div>
                            <FrontRow />
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} className={style.protocolImg} style={{ padding: 0 }} >
                            <img src={productData?.product_image} alt='qr-feature' />
                            {/* <img src={QR_flow_featured_mobile} alt='qr-feature' /> */}
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} style={{ padding: 0 }}>
                        <p className={style.protocolDesc}>
                                The <span className="font-Neue-medium">MD-03 Protocol™</span> supports acne, aging & skin longevity, body odor, discoloration, eczema, hidradentitis suppurativa, rosacea, scalp health & hair loss.
                                <div style={{padding : "6px 0px"}}></div>
                                <div>We got you covered: 
                                <span className='font-Neue-medium'> Parallel is 100% HSA/FSA eligible.</span>
                                </div>
                        </p>
                            {/* <p className='body-txt-lg text-uv-blue'><div dangerouslySetInnerHTML={{ __html: productData.product_description }}></div></p> */}

                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={15} className={style.protocolSliderContainer}>
                    <ProtocolSilder />
                </Col>
            </Row>
        )
    }
}


export default PHProtocol