import React, { useState, useEffect } from 'react'
import {Button, Space, Typography, Row, Col, Collapse, Divider, message, Spin} from 'antd';
import style from './kitResult.module.scss';
import {UpOutlined, DownOutlined, CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import moment from 'moment';
import Components from "@ph/common/components";
import {dateFormats} from "@ph/common/global/Constants";
import {getFriendlyKitCode, pick} from "@ph/common/global/Helpers";
import KitResultCollapsible from "@ph/common/components/KitResult/KitResultCollapsible";

const { Text } = Typography;
const { Panel } = Collapse;

const KitResult = ({connectionInstance}) => {
    const [fetchingReportData, setFetchingReportData] = useState(true);
    const [reportData, setReportData] = useState([]);
    const [reportRawData, setReportRawData] = useState([]);

    const baseUrl = (process.env.REACT_APP_API_URL).replace(".dev", ".qa");
    const fieldSet = ["sample_date", "result_date", "kitcode",
        "recommendedProds", "kit_type_name", "real_skin_age", "skin_age", "skin_type_name",
        "microbiome_diversity", "forehead_skin_hydration", "cheek_skin_hydration"];

    const getUserReport = async () => {
        setFetchingReportData(true);
        return await connectionInstance.get(
            `${baseUrl}/kit/all/report`
        );
    };

    function userReportSuccessHandler(res) {
        if(!res || !res.data) {
            message.error("Unable to get Report Data!");
            return ;
        }
        let report = res.data.reduce(reportDataReducer, []);

        setReportRawData([...report]);
        if(report.length) {
            setReportData([[...report][0],[...report][0],[...report][0]]);
        }
    }


    function reportDataReducer(filtered, d) {
        const {serum_recommendations, other_recommendations} = d;
        let reportObj = {...d, recommendedProds: []};
        if (serum_recommendations && serum_recommendations.length) {
            reportObj.recommendedProds = [...serum_recommendations];
        }
        if (other_recommendations && other_recommendations.length) {
            reportObj.recommendedProds =
                reportObj.recommendedProds.concat([other_recommendations[0]]);
        }
        if (d.result_date) {
            filtered.push(pick(reportObj, fieldSet));
        }
        return filtered;
    }

    useEffect(() => {
        getUserReport()
            .then(userReportSuccessHandler)
            .catch(error => {
                message.error("Something went wrong in fetching user Report!");
            }).finally(() => setFetchingReportData(false));
    }, []);

    const handleDataDisplay = (showMore) => {
        if (showMore) {
            setReportData([...reportRawData]);
        } else {
            let arr = [];
            arr.push([...reportRawData][0]);
            setReportData([...arr]);
        }
    };

    const viewReportHandler = (kitId) => window.open(`/report/${kitId}`, '_blank');

    const reportHeader = (sampleDate, kitNo, kitName, healthProfile) => (
        <div className={style.reportHeader}>
            <div className={style.reportHeaderSection1}>
                <div className={style.sampleDateContainer}>
                    <Text className={`${style.bodyText} ${style.kitDate}`}>
                        Sample Date:&nbsp; <span style={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}>
                            {moment(sampleDate).format(dateFormats.DDSPCMMMCOMSPCYYYY)}</span>
                    </Text>
                </div>
                <Text className={style.bodyText}>
                    {kitName ? kitName : "Body Blemish Discovery Kit"}:&nbsp; <strong>{getFriendlyKitCode(kitNo)}</strong>
                </Text>
            </div>
            <div className={style.reportHeaderSection2 + " " + style.showSectionDesktop}>
                <Button type="primary" className={style.button}
                        onClick={() => viewReportHandler(kitNo)}>
                    VIEW REPORT
                </Button>
            </div>
        </div>
    );

    let kitResultContent = "";

    if(fetchingReportData) {
        kitResultContent = (
            <div className="text-center">
                <Spin size="large" className={`dark-spinner card-content-spinner`} />
            </div>
        );
    } else {
        kitResultContent = (
            <>
                
                { reportData.map((report, index) => {
                        return (
                            <Components.ReportRow 
                                sampleDate = {moment(report.sample_date).format(dateFormats.DDSPCMMMCOMSPCYYYY)}
                                sampleDateLabel = "Sample Date:"
                                kitNo = {getFriendlyKitCode(report.kitcode)}
                                kitName = {report.kit_type_name}
                                buttonLabel = "VIEW REPORT"
                                onClick = {() => viewReportHandler(report.kitcode) }
                            />
                        )
                    }
                )}
                {/* <div className={`text-center ${(reportRawData.length < 2) && 'hide'}`}>
                    {reportData && reportData.length < 2 ?
                        <Button type="primary" className={style.button} onClick={() => handleDataDisplay(true)}>
                            VIEW ALL TEST REPORTS</Button>
                        :
                        <Button type="primary" className={style.button} onClick={() => handleDataDisplay(false)}>
                            VIEW LESS</Button>}
                </div> */}
            </>
        );
    }

    return (reportData.length !== 0) && (
        <div>
            <div className={style.kitContent}>
                {kitResultContent}
            </div>
        </div>
    )
}

export default KitResult;