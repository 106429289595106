import { Row, Col, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react'
import style from './quizCombineProducts.module.scss'
import { useQuizCart } from '@ph/common/context/QuizCartContext';
import QuizReadMoreDescContainer from '../QuizReadMoreDescContainer';
import PHTagContainer from '../../Global/PhTagContainer/PhTagContainer';
import { convertPrice } from '@ph/common/global/Helpers';
import { groupArrayElements } from '@ph/common/global/Helpers';
import { CSSTransition } from 'react-transition-group';
import { getProductLabel, MD03_LABEL, DO_NOT_KNOW, COPY_FLAGS, QUIZ_PAGE_TEXT, getProductName } from '@ph/common/constants/QuizConstants';

import QuizMD03 from '../QuizMD03/QuizMD03';
import { getProductDescription } from '@ph/common/constants/QuizConstants';
import { PROTOCOL_IDS } from '@ph/subject/src/api_services/Protocol/protocolFetch';
import { md03_two_swabs } from '@ph/common/assets/index';


function QuizCombineProducts({ quizProducts, tags, copyFlag, expandedProduct, handleExpandProductClick }) {

    let combineProducts = []

    if (quizProducts && quizProducts.length > 0) {
        combineProducts = groupArrayElements(quizProducts, 2)
    }

    const checkExpanded = (productArr, selectedProduct) => {
        const firstProduct = productArr[0]
        const secondProduct = productArr.length > 1 ? productArr[1] : null
        let productList = [firstProduct]
        if (secondProduct) {
            productList.push(secondProduct)
        }
        const variantIdList = productList.map(item => item.variant_id)
        if (variantIdList.includes(selectedProduct?.variant_id)) {
            return true
        }
        return false
    }

    return (
        <div>
            {combineProducts && combineProducts.map(productArr => (
                <>
                    <Row gutter={[28, 28]} align="stretch" style={{ height: '100%', alignItems: 'stretch' }} className="show-desktop-tab-hide-mobile">
                        <Col xs={24} md={24} className={style.combineProducts}>
                            <QuizProduct
                                product={productArr[0]}
                                secondProduct={productArr.length > 1 ? productArr[1] : null}
                                otherTags={tags}
                                copyFlag={copyFlag}
                                expandedProduct={expandedProduct}
                                setExpandedProduct={handleExpandProductClick}
                            />
                        </Col>
                    </Row>
                    {expandedProduct && checkExpanded(productArr, expandedProduct) && (
                        <CSSTransition
                            in={!!expandedProduct}
                            timeout={500}
                            classNames={{
                                enter: style.enter,
                                enterActive: style.enterActive,
                                exit: style.exit,
                                exitActive: style.exitActive,
                            }}
                            unmountOnExit
                        >
                            <QuizMD03 productInfo={expandedProduct} setExpandedProduct={handleExpandProductClick} className="show-desktop-tab-hide-mobile" />

                        </CSSTransition>
                    )}

                </>
            ))}
        </div>
    )
}

export default QuizCombineProducts


function QuizProduct({ product, otherTags, secondProduct, expandedProduct, setExpandedProduct }) {
    const [tags, setTags] = useState([])
    const [secondTags, setSecondTags] = useState([])
    const [isMD03Sibling, setMD03Sibling] = useState(false)

    const { isMD03Product } = useQuizCart()

    useEffect(() => {
        const tagsPresent = otherTags?.[product?.variant_id]
        const secondTagsPresent = otherTags?.[secondProduct?.variant_id]
        if (tagsPresent) {
            setTags(tagsPresent.filter(item => item !== DO_NOT_KNOW))
        }
        if (secondTagsPresent) {
            setSecondTags(secondTagsPresent.filter(item => item !== DO_NOT_KNOW))
        }
    }, [])

    useEffect(() => {
        if ((product && isMD03Product(product)) || (secondProduct && isMD03Product(secondProduct))) {
            setMD03Sibling(true)
        } else {
            setMD03Sibling(false)
        }
    }, [])

    return (
        <div className={style.productContainer}>
            <div className={`${style.combinedMetaInfo} ${style.firstMeta}`}>
                <div className={style.metaContainer}>
                    <div className={style.text}>
                        {product?.is_default_recommendation ? QUIZ_PAGE_TEXT[COPY_FLAGS.NO_REC]?.cardTitle : getProductLabel(product?.product_name)}
                    </div>
                </div>
                {secondProduct && (
                    <div className={style.metaContainer}>
                        <div className={style.text}>
                            {secondProduct?.is_default_recommendation ? QUIZ_PAGE_TEXT[COPY_FLAGS.NO_REC]?.cardTitle : getProductLabel(secondProduct?.product_name)}
                        </div>
                    </div>
                )}
            </div>
            <div className={`${style.combinedMetaInfo} ${style.secondMeta}`}>
                <div className={style.metaContainer}>
                    <div className={style.innerContainer}>
                        <div className={style.tagContainer}>
                            {tags && <PHTagContainer tags={tags} />}
                        </div>
                    </div>
                </div>
                {secondProduct && (
                    <div className={style.metaContainer}>
                        <div className={style.innerContainer}>
                            <div className={style.tagContainer}>
                                {tags && <PHTagContainer tags={secondTags} />}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={style.combinedProductInfo}>
                <ProductInfo product={product} expandedProduct={expandedProduct} setExpandedProduct={setExpandedProduct} isMD03Sibling={isMD03Sibling} />
                {secondProduct && (
                    <ProductInfo product={secondProduct} expandedProduct={expandedProduct} setExpandedProduct={setExpandedProduct} isMD03Sibling={isMD03Sibling} />
                )}
            </div>
        </div>
    )
}


const ProductInfo = ({ product, expandedProduct, setExpandedProduct, isMD03Sibling }) => {
    const [checked, setChecked] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const [isMD03, setIsMd03] = useState(false)

    const { products, addProduct, removeProduct, isMd03Member, isMD03Product, md03MainProduct } = useQuizCart()

    const handleToggleCheck = () => {
        if (checked) {
            removeProduct(product?.variant_id)
        } else {
            if (isMD03) {
                addProduct({ ...product, quantity: 1, product_category: 'MD03' })
            } else {
                addProduct({ ...product, quantity: 1 })
            }
        }
    }

    useEffect(() => {
        const isPresent = products.filter(item => item.variant_id === product.variant_id)
        if (isPresent && isPresent.length > 0) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [products])

    useEffect(() => {
        if (expandedProduct?.variant_id === product?.variant_id) {
            setIsExpanded(true)
        } else {
            setIsExpanded(false)
        }
    }, [expandedProduct])

    useEffect(() => {
        if (product && isMD03Product(product)) {
            setIsMd03(true)
        } else {
            setIsMd03(false)
        }
    }, [product])

    useEffect(() => {
        if (product) {
            if (isMD03Product(product)) {
                addProduct({ ...product, quantity: 1, product_category: 'MD03' })
            } else {
                addProduct({ ...product, quantity: 1 })
            }
        }
    }, [])


    return (
        <Row className={`${style.product} ${isExpanded ? style.expanded : ''}`}>
            <Col xs={10} md={9}>
                {/* {[PROTOCOL_IDS.BODY_BLEMISH_PACK_1, PROTOCOL_IDS.BODY_ODOR_PACK_1, PROTOCOL_IDS.SCALP_HEALTH_PACK_1].includes(product?.variant_id)
                    ? < img src={md03_two_swabs} alt='image' className={style.image} />
                    : <img src={product.image_url} alt='image' className={style.image} />
                }  */}
                <img src={product.image_url} alt='image' className={style.image} />
            </Col>
            <Col xs={14} md={15}>
                <div className={style.productInfo}>
                    <div>
                        <div className={style.title}>
                            {isMD03 ? <>{getProductName(product?.product_name)}</> : product?.variant_id === PROTOCOL_IDS.BLUE_PEPTIDE_CREAM_VARIANT ? `${product?.product_name} (MIDI)` : product?.product_name}
                        </div>
                        <div className={style.priceContainer}>
                            {isMD03Product(product) ? (
                                <MD03ProductPrice md03MainProduct={md03MainProduct} product={product} />
                            ) : (
                                <span className={style.price}>{`$${convertPrice(isMd03Member ? product?.price : product?.ala_cart_price)}/mo`}</span>
                            )}
                            <span className={style.discountPrice}>{`$${convertPrice(product?.compare_at_price)}`}</span>
                        </div>
                    </div>
                    {!isMD03 && (
                        <div className={`${style.first} show-mobile-hide-desktop`} onClick={() => window.open(product?.ala_cart_shopify_product_url, '_blank')}>
                            Buy one time @${product?.compare_at_price}
                        </div>
                    )}
                    <QuizReadMoreDescContainer
                        description={getProductDescription(product)}
                        rowCount={6}
                        className={`${style.description} show-desktop-hide-mobile`}
                        readMoreColor={isExpanded ? "#2D2926" : "#767472"}
                    />
                </div>
            </Col>
            <Col xs={24} className="show-mobile-hide-desktop">
                <div className={style.mobileDesc}>
                    <QuizReadMoreDescContainer
                        description={getProductDescription(product)}
                        rowCount={6}
                        className={`${style.description}`}
                        readMoreColor={isExpanded ? "#2D2926" : "#767472"}
                    />
                </div>
            </Col>
            <Col xs={24} md={24} className={style.buttonCol}>
                <div className={`${style.buttonContainer} ${isMD03 ? style.end : ''}`}>
                    {!isMD03 && (
                        <div className={`${style.first} show-desktop-hide-mobile`} onClick={() => window.open(product?.ala_cart_shopify_product_url, '_blank')}>
                            Buy one time @${product?.compare_at_price}
                        </div>
                    )}
                    <div className={`${style.addButton} ${checked ? style.checked : ''}`} onClick={handleToggleCheck}>
                        {checked ? 'Added' : 'Add'} to cart
                        <Checkbox checked={checked} className={isExpanded ? style.checkboxExpanded : style.checkbox} />
                    </div>
                </div>
                {isMD03Sibling && (
                    <div className={style.includedCol} >
                    </div>
                )}
            </Col>

            {isMD03 && !isExpanded && (
                <div className={style.includedContainer} onClick={() => setExpandedProduct(product)}>
                    <div className={style.icon}>
                        <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.502036 32.7086C0.509146 33.2608 0.962589 33.7027 1.51483 33.6956L10.5141 33.5797C11.0663 33.5726 11.5082 33.1192 11.5011 32.5669C11.494 32.0147 11.0406 31.5728 10.4883 31.5799L2.489 31.6829L2.386 23.6836C2.37889 23.1313 1.92545 22.6894 1.37321 22.6965C0.82097 22.7036 0.379056 23.1571 0.386166 23.7093L0.502036 32.7086ZM32.5019 1.90021C32.4948 1.34797 32.0413 0.906058 31.4891 0.913169L22.4898 1.02904C21.9376 1.03615 21.4957 1.48959 21.5028 2.04183C21.5099 2.59407 21.9633 3.03598 22.5156 3.02887L30.5149 2.92588L30.6179 10.9252C30.625 11.4775 31.0785 11.9194 31.6307 11.9123C32.1829 11.9051 32.6249 11.4517 32.6177 10.8995L32.5019 1.90021ZM2.2181 33.3936L32.2181 2.61103L30.7858 1.21514L0.785801 31.9977L2.2181 33.3936Z" fill="white" />
                        </svg>
                    </div>
                    <div className={`${style.text}`} >
                        What’s Included?
                    </div>
                </div>
            )}

            {isExpanded && (
                <div className={style.triangleContainer}>
                    <div className={style.triangle}></div>
                </div>
            )}

        </Row>
    )
}

const MD03ProductPrice = ({ md03MainProduct, product }) => {
    return (
        <span className={style.price}>
            {md03MainProduct?.variant_id != product?.variant_id
                ? `$${convertPrice(product?.md03_discount_variant_price)}/mo`
                : `$${convertPrice(product?.price)}/mo`}
        </span>

    )

}
