import React from 'react';
import style from "./Report.module.scss";
import { Col, Row, Typography } from "antd";
import Marquee from "react-fast-marquee";
import { marquee_1, marquee_2, marquee_3 } from "@ph/common/assets";
import { ExclamationCircleFilled } from '@ant-design/icons';


const { Text } = Typography;
function ReportUnavailable({ isAdmin, user, customKitcode, errorMessage, errorStatus }) {

    const getErrorMessage = (errorMessage) => {
        const error = errorMessage?.toUpperCase()
        switch (error) {
            case "ORPHAN KITCODE":
                return "Kit found, but not assigned, linked, or registered."
            case "REGISTERED KITCODE AND INCOMPLETE HEALTHPROFILE":
                return "Kit registered; health intake form missing."
            case "UNREGISTERED KITCODE AND INCOMPLETE HEALTHPROFILE":
                return "Kit not registered and health intake form missing."
            case "UNREGISTERED KITCODE":
                return "Kit not yet registered."
            case "INCOMPLETE HEALTH PROFILE":
                return "health intake form missing."
            case "INVALID KITCODE":
                return "Kit code not valid."
            case "FAILED TO GET REPORT OR NOT GENERATED":
                return "Failed to get report or report is not yet generated."
            case "KIT STATUS NOT COMPLETE":
                if (errorStatus === 'REJECTED') {
                    return "Kit is rejected."
                } else if (errorStatus === 'ON_HOLD') {
                    return "Kit is on-hold."
                }
                return "Kit not sequenced. Check status in lab portal."
            default:
                return "Something went wrong!";
        }
    }

    return (
        <>
            <div className='viewBoxWidth'>
                <div className={style.box} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {!isAdmin && <Text className={style.h1Text} style={{ fontFamily: 'Light' }}>Hi {(user && user.first_name) && user.first_name},</Text>}
                    {
                        isAdmin ? <ReportError message={getErrorMessage(errorMessage)} />
                            :
                            <Text className={style.h2Text}>
                                Seems like there are no skin microbiome reports linked to {isAdmin ? customKitcode.toString().toUpperCase() : 'your account'}
                            </Text>

                    }
                    {/* <Divider type="horizontal" className={style.horizontalseparator} /> */}
                </div>
            </div>
            <Text className={`${style.quincyText} ${style.box} h3`}>
                Explore our preorder offerings
            </Text>
            <div className={style.box}>
                <div className={style.bannerContent}>
                    <div className={style.marqueeSection}
                         onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}`}>
                        <Marquee className={style.marqueeContent} gradient={false} loop={0}>
                            <img src={marquee_1} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_2} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_3} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_1} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_2} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_3} className={style.marqueeImage} alt="PH"/>
                        </Marquee>
                    </div>
                    {/* <div className='viewBoxWidth'> */}
                    <div className={`${style.bannerSection} viewBoxWidth`}>
                        <div className={`${style.banner} ${style.box}`}>
                            <Row justify="space-around" align="middle">
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <div className={`h2 ${style.bannerTitle}`}>
                                        All our products are made responsibly with precision and care.
                                    </div>
                                    {/* <Button className={style.bannerButton} type="secondary">SHOP NOW</Button> */}
                                </Col>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <img className={style.bannerImage} alt="Parallel"/>
                                </Col>
                            </Row>
                            {/* <Button className={style.bannerButtonSmall} type="secondary">SHOP NOW</Button> */}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    );
}

export default ReportUnavailable;

const ReportError = ({ message }) => {
    return (
        <div className='center-flex' style={{ flexDirection: 'column', gap: '16px' }}>
            <ExclamationCircleFilled style={{ color: "#FF677B ", fontSize: '44px' }} />
            <Text className="h5" style={{ color: '#a50832' }}>
                {message}
            </Text>
        </div>
    )
}