import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import QuizFaceArea from '../QuizFaceArea/QuizFaceArea'
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NO_ISSUES_TEXT } from '@ph/common/global/Constants'
import { useEffect } from 'react'
import { scrollToContainer } from '@ph/common/global/Helpers'
import QuizScalpArea from '../QuizScalpArea/QuizScalpArea'

// Validation schema
const validationSchema = Yup.object().shape({
  scalpIssues: Yup.array().min(1, 'At least one option must be selected'), // Ensure at least one face area is selected
});

function PHQScalpArea() {
  const dispatch = useDispatch();
  const Que = getQuestionByQueId(queConstants.phq_scalp_issues);
  const scalpIssueAns = useSelector(state => state.quizData[queConstants.phq_scalp_issues]);

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      scalpIssues: scalpIssueAns || [],
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.scalpIssues) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.scalpIssues])

  return (
    <>
      <div className={style.questionContainer}>
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQSpacer'></div>

            <Controller
              name="scalpIssues"
              control={control}
              render={({ field }) => (
                <QuizScalpArea
                  values={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    dispatch(updateQuizData({ queID: queConstants.phq_scalp_issues, ans: value }));
                  }}
                />
              )}
            />
            <p className="PHQErrorMessage" id="errorMessage">
              {errors.scalpIssues && (
                <span >{errors.scalpIssues.message}</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <QueButtons prevQue={Que} currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  );
}

export default PHQScalpArea;
