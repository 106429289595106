import { PROTOCOL_IDS } from "@ph/subject/src/api_services/Protocol/protocolFetch"

export const queConstants = {
    'phq_name': 'name',
    'phq_email': 'email',
    'phq_age': 'age',
    'phq_zipcode': 'zipcode',
    'phq_gender': 'gender',
    'phq_ethnicity': 'ethnicity',
    'phq_hallmarks': 'hallmarks',
    'phq_face_issues': 'face_skin_issues',
    'phq_body_issues': 'body_issues',
    'phq_face_area': 'face_blemish_area',
    'phq_scalp_area': 'scalp_area',
    'phq_scalp_issues': 'scalp_issues',
    'phq_current_career': 'career',
    'phq_workstress': 'stress',
    'phq_body_blemish_area': 'body_blemish_area',
    'phq_body_odor_area': 'body_odor_area',
    'phq_cleanser_gentleness': 'cleanser_gentleness',
    'phq_skin_moisturizer': 'moisturizer_protectivity',
    'phq_doctor_skincare': 'prescription',
    'phq_skincare_adventure': 'skincare_adventure',
    'phq_aging_process': 'aging_guidance',
    'phq_skin_pamper': 'skin_pamper',
    'phq_skincare_curiosity': 'skincare_curiosity',
    "phq_most_intrested_in": 'skin_interested_in',
    'phq_parallel': 'parallel',
    "phq_anti_aging_products": 'anti_aging_products_treatments',
    "phq_when_it_comes_to_parallel": 'recommandations_type',
    "phq_issues_priority": 'issues_priority',
}

export const COPY_FLAGS = {
    MD03: 'MD03',
    PART_REC: 'PARTIAL RECOMMENDATIONS',
    NO_REC: 'NO RECOMMENDATIONS'
}

export const QUIZ_PAGE_TEXT = {
    [COPY_FLAGS.MD03]: {
        welcomeText: <>Based on your quiz results, we've curated some personalized recommendations tailored to your skin concerns and goals. We have your recommended <span className="font-Neue-medium">MD-03 Protocol™</span> to best help you achieve your skin health goals. Explore additional product suggestions below to find the perfect products for your skincare journey.</>,
        cardTitle: 'Since you reported that you are facing',
        showMd03: true
    },
    [COPY_FLAGS.PART_REC]: {
        welcomeText: <>Based on your quiz results, you indicated that you currently don't experience any active skin or odor issues on your face and body, but you are concern about skin aging. We recommend the following products to help you address your skin aging concerns to and maintain healthy skin. Explore the suggestions below to find the perfect products for your skincare journey.</>,
        cardTitle: 'Since you reported that you are facing',
        showMd03: false
    },
    [COPY_FLAGS.NO_REC]: {
        welcomeText: <>Based on your quiz results, you indicated that you currently don't experience any concerning skin or odor issues on your face and body. We recommend the following products to help you maintain healthy skin. Explore the suggestions below to find the perfect products for your skincare journey.</>,
        cardTitle: 'Since you reported that you have no issues, we are recommending for',
        showMd03: false
    }
}

export const QUIZ_PLAN_MODAL_TYPES = {
    WELCOME: 'WELCOME',
    DOWNGRADE: 'DOWNGRADE',
    PRIORITY: 'PRIORITY'
}

export const QUIZ_PLAN_MODAL_DETAILS = {
    [QUIZ_PLAN_MODAL_TYPES.WELCOME]: {
        title: 'Welcome!',
        subTitle: <><span className="font-Neue-medium">You're upgrading back to the MD-03 Protocol™.</span> <br />Add more protocols and enjoy exclusive discounted pricing, 24/7 access to Microbiome Dermatology™ doctors, and skin microbiome testing twice a year.</>,
        cancelText: '',
        successText: ''
    },
    [QUIZ_PLAN_MODAL_TYPES.DOWNGRADE]: {
        title: 'Oh No!',
        subTitle: <>By removing your MD-03 Protocol™, you will lose exclusive discounting, 24/7 access to our physicians, and free testing twice a year.</>,
        cancelText: 'Remove',
        successText: 'Keep MD03 Protocol™'
    },
    [QUIZ_PLAN_MODAL_TYPES.PRIORITY]: {
        title: 'Please Confirm',
        subTitle: <>Since you are removing your prioritized MD-03 Protocol (Facial), we are making MD-03 Protocol (Body) as your prioritized selection. </>,
        cancelText: 'CONFIRM',
        successText: 'CANCEL'
    }
}

export const getPrioritySubtitle = (firstProduct, secondProduct) => {
    return <>You are removing the MD-03 Protocol™ ({MD03_LABEL[firstProduct?.product_name]}). The MD-03 Protocol™ ({MD03_LABEL[secondProduct?.product_name]}) will now be your prioritized selection.</>
}


export function getProductLabel(productName) {
    switch (productName.toLowerCase()) {
        case "skin discovery test":
            return "Since you reported that on your face, you are experiencing"
        case "body blemish test + serum":
            return "Since you reported that on your body, you are experiencing";
        case "odor discovery test + serum":
            return "Since you reported that you are facing";
        case "body blemish test":
            return "Since you reported that on your body, you are experiencing";
        case "odor discovery test":
            return "Since you reported that you are facing";
        case "scalp health test":
            return "Since you reported that on your scalp, you are experiencing";
        case "personalized prescription":
            return "Because you reported you are experiencing a concern and/or are open to";
        case "blue biotic™ multi-effect peptide cream":
            return "Based on your skin aging goals and priorities, we recommend you focus on";
        default:
            return 'Since you reported that you are facing';
    }
}

export function getProductName(productName) {
    switch (productName.toLowerCase()) {
        case "skin discovery test":
            return "Facial Skin Discovery"
        case "body blemish test + serum":
            return "Body Blemish Discovery";
        case "odor discovery test + serum":
            return "Odor Discovery";
        case "body blemish test":
            return "Body Blemish Discovery";
        case "odor discovery test":
            return "Odor Discovery";
        case "scalp health test":
            return "Scalp Health Discovery";
        default:
            return 'Skin Discovery Test';
    }
}


export function getProductDescription(product) {
    switch (product?.product_name?.toLowerCase()) {
        case "skin discovery test":
            return "We all experience skin issues on our faces, but since you’ve indicated that it bothers you, we recommend the Skin Discovery Test for your face. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the skin issue(s) on your face, allowing us to provide targeted solutions to address it."
        case "body blemish test + serum":
            return "We all experience body blemish, but since you’ve indicated that it bothers you, we recommend the Body Blemish Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the blemish, allowing us to provide targeted solutions to address it.";
        case "body blemish test":
            return "We all experience body blemish, but since you’ve indicated that it bothers you, we recommend the Body Blemish Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the blemish, allowing us to provide targeted solutions to address it.";
        case "odor discovery test + serum":
            return "We all experience body odor, but since you’ve indicated that it bothers you, we recommend the Body Odor Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the odor, allowing us to provide targeted solutions to address it.";
        case "odor discovery test":
            return "We all experience body odor, but since you’ve indicated that it bothers you, we recommend the Body Odor Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the odor, allowing us to provide targeted solutions to address it.";
        case "scalp health test":
            return "We all experience scalp health issues, but since you’ve indicated that it bothers you, we recommend the Scalp Health Test. This test will help identify any bacterial imbalances or overgrowths that may be contributing to the scalp, allowing us to provide targeted solutions to address it.";
        default:
            return product?.description?.short_text
    }
}

export const MD03_LABEL = {
    "Skin Discovery Test": "Facial",
    "Body Blemish Test": "Blemish",
    "Odor Discovery Test": "Odor",
    "Body Blemish Test + Serum": "Blemish",
    "Odor Discovery Test + Serum": "Odor",
    "Scalp Health Test": "Scalp"
}

export const DO_NOT_KNOW = `I don't know`

export const ISSUES_PRIORITY = [
    {
        title: 'Face',
        icon: <svg width="248" height="177" viewBox="0 0 128 190" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_9756_7719)">
                <path d="M11.1931 131.122C10.6654 144.124 10.3344 152.208 4.36816 166.499H123.984C123.984 166.499 116.444 152.63 116.515 131.122H11.1931Z" fill="#CFD1D2" />
                <path d="M11.194 88.9318L8.17958 87.8981C8.17958 87.8981 -0.286785 49.714 13.3274 26.4707C26.9416 3.22741 44.7598 1.1743 61.1156 2.68213C61.1156 2.68213 68.2268 -0.783978 79.891 1.02257C91.5552 2.83386 109.584 13.5119 116.516 30.2023C123.448 46.8928 122.326 64.9677 121.02 87.8839L116.355 88.9318C116.355 88.9318 114.427 66.2574 111.708 56.9118C108.989 47.5661 99.4312 32.8245 85.8215 29.3346C72.2118 25.8401 65.0111 28.6187 65.0111 28.6187C65.0111 28.6187 55.5966 27.0966 45.3457 29.9084C35.0904 32.7154 22.6569 40.1407 16.8249 56.5182C10.2816 74.9014 11.194 88.9365 11.194 88.9365V88.9318Z" fill="#CFD1D2" />
                <path d="M11.1935 88.9321C11.1935 88.9321 6.645 86.3669 3.93916 88.9321C1.2378 91.4973 -0.895568 100.89 3.93916 113.384C8.77389 125.879 9.87412 131.346 15.2992 132.005" fill="white" />
                <path d="M11.1935 88.9321C11.1935 88.9321 6.645 86.3669 3.93916 88.9321C1.2378 91.4973 -0.895568 100.89 3.93916 113.384C8.77389 125.879 9.87412 131.346 15.2992 132.005" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M116.807 88.9321C116.807 88.9321 121.355 86.3669 124.061 88.9321C126.763 91.4973 128.896 100.89 124.061 113.384C119.226 125.879 118.126 131.346 112.701 132.005" fill="white" />
                <path d="M116.807 88.9321C116.807 88.9321 121.355 86.3669 124.061 88.9321C126.763 91.4973 128.896 100.89 124.061 113.384C119.226 125.879 118.126 131.346 112.701 132.005" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M116.3 89.6858C116.3 89.6858 112.319 143.067 109.189 150.004C106.058 156.941 85.1133 181.545 74.4823 186.495C73.315 187.04 66.8031 188.908 63.8155 188.908C63.8155 188.908 58.2696 188.605 53.1487 186.495C42.3298 182.033 21.5731 156.941 18.4424 150.004C15.3116 143.067 11.3311 89.6858 11.3311 89.6858C11.3311 89.6858 10.0252 51.9949 30.5135 37.6468C51.3373 23.0664 65.0007 28.6141 65.0007 28.6141C65.0007 28.6141 70.1082 26.8265 82.7295 28.7042C97.3992 30.8853 107.918 44.7166 111.702 56.9119C115.589 69.444 116.881 82.27 116.295 89.6858H116.3Z" fill="white" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M46.042 150.758C46.042 150.758 63.0284 156.339 83.8745 150.004" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M53.8652 158.212C53.8652 158.212 62.9667 164.845 76.3394 157.458" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M50.0234 148.046C50.0234 148.046 55.9271 144.845 58.946 144.248C61.9649 143.65 63.0159 145.447 64.8496 145.447C66.6834 145.447 66.424 144.105 70.23 144.124C74.0361 144.148 78.8932 148.046 78.8932 148.046" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M61.9697 5.5459C66.6613 11.2026 64.9573 27.8646 64.9573 27.8646" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
            </g>
            <defs>
                <clipPath id="clip0_9756_7719">
                    <rect width="127" height="189" fill="white" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>
    },
    {
        title: 'Body Blemish',
        icon:
            <svg width="248" height="181" viewBox="0 0 159 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38.5418 164.992C31.6569 154.785 33.6459 141.441 26.7035 117.929C23.8444 108.249 5.8479 89.5003 6.29734 79.3895C6.88065 66.3326 10.8586 52.8164 16.5291 40.8403C20.9278 31.5522 36.3042 30.3373 44.3271 25.765C52.0631 21.3553 57.9153 15.5777 64.6568 8.2983C73.9037 -1.67857 82.8924 -2.74036 93.5928 5.55298C101.204 11.4454 104.389 29.7347 110.117 32.9487C128.343 43.1839 140.822 40.8021 145.086 47.7467C150.795 57.0252 152.631 81.2453 150.155 86.9463C141.912 105.934 134.52 101.056 130.37 125.658C127.195 144.445 134.31 153.427 127.319 165.671C121.238 176.336 103.872 170.731 81.3911 184.123C56.5861 198.902 44.1263 173.256 38.5323 164.982L38.5418 164.992Z" fill="white" />
                <path d="M2.37699 76.9502C1.65981 83.8948 1.26775 91.7959 1 100.568" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M63.4331 10.5557C63.1749 16.0941 62.6394 21.9196 61.5876 24.3205C59.3213 29.4668 55.5632 31.3321 43.4668 36.5166C31.3798 41.7011 18.6426 40.228 11.0309 49.1144C6.11585 54.8538 3.68701 64.3045 2.37695 76.9502" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M32.499 153.522C32.2025 157.827 31.6001 163.107 30.5674 169.86" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M25.1357 81.3027C25.1357 81.3027 29.2094 116.102 30.5673 127.772C31.5809 136.525 33.3977 140.619 32.4988 153.513" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M71.6281 83.0439C71.6281 83.0439 70.9683 92.906 64.7432 94.3217" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M81.6307 166.598C82.7495 160.266 81.8698 153.646 81.9176 150.136C82.0419 141.728 80.8849 121.477 80.7701 118.263C80.6554 115.049 81.1526 109.884 81.1526 109.884" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M90.0459 83.0439C90.0459 83.0439 90.1033 86.0667 91.3655 88.6876C92.6278 91.3086 95.8503 92.9252 98.0496 93.1069" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M131.183 84.4014C131.183 84.4014 126.478 133.607 126.115 138.906C125.752 144.215 124.489 152.91 128.659 165.039C129.969 168.846 131.059 173.581 131.938 178.278" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M98.1359 10.5645C98.1359 10.5645 97.7439 18.0256 98.3367 21.6031C98.9392 25.1806 98.6236 27.572 104.351 31.0634C109.286 34.067 119.718 37.8454 125.637 39.6246C131.557 41.4134 141.913 44.3691 144.485 46.2918C145.546 47.0762 151.246 52.299 153.015 56.7374C155.138 62.075 157.356 73.0658 158.236 88.0742C159.116 103.083 158.551 115.374 158.551 115.374" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
            </svg>

    },
    {
        title: 'Body Odor',
        icon:
            <svg width="400" height="177" viewBox="0 0 182 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_10049_8290)">
                    <path d="M16.041 20.4896C15.4638 18.2559 14.96 15.9946 14.6393 13.623C14.3461 11.4353 14.0621 9.15562 14.7767 7.069C15.4913 4.964 17.1862 3.24508 19.1834 2.19717C23.9749 -0.312286 30.1131 0.992996 34.5015 4.11833C38.8899 7.24366 41.8582 11.8857 44.5151 16.5185C47.1719 21.1514 49.6639 25.9681 53.4476 29.7828C59.7233 36.1254 68.729 38.9658 77.3592 41.558C85.0183 43.856 92.4941 46.8343 100.51 46.9262C109.022 47.0181 117.496 46.4574 125.952 45.5933C136.241 44.4903 147.839 42.9092 156.011 50.6306C162.818 57.0651 162.635 66.6617 165.429 74.8887C168.324 83.4006 173.711 91.8115 177.697 100.011C181.105 107.024 183.936 114.645 183.221 122.375C182.726 127.753 180.216 133.25 175.47 136.017C169.451 139.519 161.737 137.809 155.214 135.318C145.787 131.724 136.928 126.696 129.049 120.482C122.104 115.003 115.48 109.148 106.411 107.42C97.478 105.719 88.3073 108.219 79.2007 108.238C47.0253 108.302 31.4965 79.54 23.9474 52.8735C21.9319 45.7496 20.3195 38.5337 18.817 31.2903C18.0382 27.5583 16.948 24.0653 16.0226 20.4987L16.041 20.4896Z" fill="white" />
                    <path d="M11.4785 18.835C11.4785 18.835 26.6867 85.6068 33.6586 91.9678C40.6305 98.3287 86.2642 105.958 136.964 94.514" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                    <path d="M33.0264 11.8389C33.0264 11.8389 47.6024 50.4183 59.6499 64.5191C59.6499 64.5191 83.7356 56.9907 102.746 55.0879C121.756 53.176 136.973 53.176 136.973 53.176" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                    <path d="M136.085 91.1953C136.085 91.1953 140.831 104.818 142.947 119.838" stroke="#2D2A26" stroke-width="1.5" stroke-miterlimit="10" />
                </g>
                <defs>
                    <clipPath id="clip0_10049_8290">
                        <rect width="181" height="137" fill="white" transform="translate(0.962891 0.978516)" />
                    </clipPath>
                </defs>
            </svg>
    },
    {
        title: 'Scalp',
        icon: <svg width="248" height="191" viewBox="0 0 146 191" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.5979 33.7601C50.5583 36.0082 55.4926 40.8272 57.9021 46.7183C60.8073 53.8316 60.0464 61.9478 62.3982 69.257C63.8393 73.7301 66.7906 78.088 71.2753 79.4829C80.3138 82.2729 93.2721 68.1387 97.3879 83.0453C100.881 95.7153 95.2204 110.599 101.227 122.566C102.288 124.687 103.855 126.693 106.057 127.581C109.032 128.78 112.548 127.615 114.969 125.517C118.601 122.37 122.094 117.136 124.815 113.158C126.256 111.049 134.141 96.188 136.159 96.188C140.102 90.2853 144.01 85.547 145.105 78.4799C146.765 67.8159 146.097 56.7714 143.261 46.361C137.623 25.69 122.774 7.56687 101.354 2.41354C80.1409 -2.69367 57.2103 3.48572 39.2371 15.856C35.1559 18.669 27.8467 23.073 31.801 29.2063C34.3258 33.126 40.7358 32.2959 44.5864 33.7601H44.5979Z" fill="white" />
            <path d="M126.336 183.783C126.336 183.783 110.38 148.251 117.666 133.944C124.952 119.637 134.452 111.417 141.796 76.9003C147.802 48.6435 131.051 25.2978 115.43 16.1902C99.8198 7.08248 82.6881 2.30959 52.4829 9.22681C38.268 12.4894 15.7524 24.7214 13.0893 49.0124C11.1986 66.2363 15.4181 74.1911 7.25579 88.9939C4.36208 94.2395 1.57214 98.0785 1.06488 100.995C0.557618 103.912 3.04782 105.388 7.72847 107.636C7.72847 107.636 9.94197 111.44 7.32496 115.452C4.25832 120.144 6.4142 121.678 10.3224 122.646C10.3224 122.646 8.63923 122.854 7.32496 126.647C6.63324 128.653 7.72848 131.846 10.7259 132.042C10.7259 132.042 15.3259 132.042 14.2076 146.165C13.7349 152.114 19.5454 157.348 29.7829 156.045C35.1437 155.365 46.0729 151.975 53.5666 147.871C53.5666 147.871 56.564 159.965 57.5094 166.328C59.0888 176.877 58.9736 182.457 58.1781 190.055" stroke="#231F20" stroke-width="1.5" />
            <path d="M76.8306 124.56C76.1273 127.557 73.6602 133.794 71.4351 136.157C66.4086 141.495 60.4598 144.181 50.9141 149.3" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M88.0719 99.0927C88.0719 99.0927 94.6202 94.3544 102.056 97.1443C109.492 99.9343 112.467 107.186 108.904 117.504C105.33 127.822 97.8943 137.391 94.9199 139.443C91.9455 141.495 85.9967 142.325 82.123 132.849" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M24.7568 26.3823C24.7568 26.3823 48.944 25.0449 58.8241 36.8964C68.7158 48.7479 57.752 67.6896 69.1308 78.4805C72.9353 82.0774 75.9327 85.0749 75.9327 85.0749" stroke="#231F20" stroke-width="1.5" stroke-miterlimit="10" stroke-dasharray="5 4" />
        </svg>
    }
]

export const CART_META_DATA = {
    [PROTOCOL_IDS.SKIN_TEST_WITH_PACK_1]: {
        kitName: "FACIAL SKIN MICROBIOME TESTING (EVERY 6 MONTHS)",
        reportName: "FACIAL SKIN HEALTH REPORT (EVERY 6 MONTHS)",
        consult: "UNLIMITED 1:1 CLINICAL GUIDANCE",
        serum: "FACIAL ACTIVE PHAGE SERUM (MONTHLY)"
    },
    [PROTOCOL_IDS.BODY_BLEMISH_PACK_1]: {
        kitName: "BODY BLEMISH MICROBIOME TESTING (EVERY 6 MONTHS)",
        reportName: "BLEMISH HEALTH REPORT (EVERY 6 MONTHS)",
        consult: "UNLIMITED 1:1 CLINICAL GUIDANCE",
        serum: "BODY BLEMISH ACTIVE PHAGE SERUM (MONTHLY)"
    },
    [PROTOCOL_IDS.BODY_ODOR_PACK_1]: {
        kitName: "ODOR SKIN MICROBIOME TESTING (EVERY 6 MONTHS)",
        reportName: "ODOR HEALTH REPORT (EVERY 6 MONTHS)",
        consult: "UNLIMITED 1:1 CLINICAL GUIDANCE",
        serum: "ODOR ACTIVE PHAGE SERUM (MONTHLY)"
    },
    [PROTOCOL_IDS.SCALP_HEALTH_PACK_1]: {
        kitName: "SCALP SKIN MICROBIOME TESTING (EVERY 6 MONTHS)",
        reportName: "SCALP HEALTH REPORT (EVERY 6 MONTHS)",
        consult: "UNLIMITED 1:1 CLINICAL GUIDANCE",
        serum: "SCALP ACTIVE PHAGE SERUM (MONTHLY)"
    },
    [PROTOCOL_IDS.SKIN_TEST_DISCOUNT_ID]: {
        kitName: "FACIAL SKIN MICROBIOME TESTING (EVERY 6 MONTHS)",
        reportName: "FACIAL SKIN HEALTH REPORT (EVERY 6 MONTHS)",
        consult: "UNLIMITED 1:1 CLINICAL GUIDANCE",
        serum: "FACIAL ACTIVE PHAGE SERUM (MONTHLY)"
    },
    [PROTOCOL_IDS.BODY_BLEMISH_DISCOUNT_ID]: {
        kitName: "BODY BLEMISH MICROBIOME TESTING (EVERY 6 MONTHS)",
        reportName: "BLEMISH HEALTH REPORT (EVERY 6 MONTHS)",
        consult: "UNLIMITED 1:1 CLINICAL GUIDANCE",
        serum: "BODY BLEMISH ACTIVE PHAGE SERUM (MONTHLY)"
    },
    [PROTOCOL_IDS.BODY_ODOR_DISCOUNT_ID]: {
        kitName: "ODOR SKIN MICROBIOME TESTING (EVERY 6 MONTHS)",
        reportName: "ODOR HEALTH REPORT (EVERY 6 MONTHS)",
        consult: "UNLIMITED 1:1 CLINICAL GUIDANCE",
        serum: "ODOR ACTIVE PHAGE SERUM (MONTHLY)"
    },
    [PROTOCOL_IDS.SCALP_HEALTH_DISCOUNT_ID]: {
        kitName: "SCALP SKIN MICROBIOME TESTING (EVERY 6 MONTHS)",
        reportName: "SCALP HEALTH REPORT (EVERY 6 MONTHS)",
        consult: "UNLIMITED 1:1 CLINICAL GUIDANCE",
        serum: "SCALP ACTIVE PHAGE SERUM (MONTHLY)"
    }
}

