import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import { Input } from 'antd'
import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData, updateUserInfoDirty } from '@ph/common/store/quizData.reducer'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CountryZipcodeValidator from '../CountryZipocode/CountryZipcodeValidator'

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1, "Name is required").required('Name is required'),
  email: Yup.string().email('Email is invalid').required('Email is required'),
  age: Yup.number().required('Age is required').typeError('Age is required and must be a number').positive('Age must be positive').integer('Age must be an integer'),
  zipcode: Yup.string().required('Zipcode is required').typeError('Zipcode is required and must be a valid'),
});

function PHQName() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const ans = useSelector(state => state.quizData[queConstants.phq_name]);
  const emailAns = useSelector(state => state.quizData[queConstants.phq_email]);
  const ageAns = useSelector(state => state.quizData[queConstants.phq_age]);
  const zipAns = useSelector(state => state.quizData[queConstants.phq_zipcode]);

  const Que = getQuestionByQueId(queConstants.phq_name)

  const { control, reset, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: ans || '',
      email: emailAns || '',
      age: ageAns || '',
      zipcode: zipAns || '',
    },
  });

  const onSubmit = (data) => {
    // Do nothing
  };

  const getAgeZipcodeError = (errors) => {
    if (errors.age && errors.zipcode) {
      return 'Age and Zipcode are required and must be valid'
    }
    if (errors.age) {
      return 'Age is required and must be valid'
    }
    if (errors.zipcode) {
      return 'Zipcode or city is required and must be valid'
    }
  }



  useEffect(() => {
    if (!ans && !emailAns && !ageAns && !zipAns) {
      reset({
        name: '',
        email: '',
        age: '',
        zipcode: ''
      })
    }
  }, [ans, emailAns, ageAns, zipAns])


  return (
    <>
      <div className={style.questionContainer}>
        <div className={`${style.questionWrap}`}>
          <div className={`${style.questionWrapper} `}>
            <div className='PHQQuestionText'>First things first, whose skin are we talking about?</div>
            <div style={{ maxWidth: '398px', margin: 'auto' }}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    className={`${style.bigInput} PHQInputBottomLine`}
                    placeholder={'enter your name'}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      dispatch(updateQuizData({ queID: queConstants.phq_name, ans: e.target.value }));
                      dispatch(updateUserInfoDirty(true))
                    }}
                  />
                )}
              />
              <p className="PHQErrorMessage">
                {errors.name && (
                  <span >{errors.name.message}</span>
                )}
              </p>
            </div>
            <div className='PHQSmallSpacer'></div>
            <div className='PHQQuestionText'>What is your email?</div>
            <div className={style.midInput}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    className={`PHQInputBottomLine`}
                    placeholder={'@'}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      dispatch(updateUserInfoDirty(true))
                      dispatch(updateQuizData({ queID: queConstants.phq_email, ans: e.target.value }));
                    }}
                  />
                )}
              />
              <p className="PHQErrorMessage">
                {errors.email && (
                  <span >{errors.email.message}</span>
                )}
              </p>
            </div>
            <div className='PHQSmallSpacer'></div>
            <div className='PHQQuestionText'>
              {`I am`}
              <Controller
                name="age"
                control={control}
                render={({ field }) => (
                  <Input
                    className="PHQInputBottomLine"
                    placeholder={'age'}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      dispatch(updateQuizData({ queID: queConstants.phq_age, ans: e.target.value }));
                    }}
                    style={{ display: 'inline-block', maxWidth: '121px' }}
                  />
                )}
              />
              {'years young,'}
              <Controller
                name="zipcode"
                control={control}
                render={({ field }) => (
                  <CountryZipcodeValidator
                    value={field?.value}
                    onChange={(value) => {
                      field.onChange(value);
                      dispatch(updateQuizData({ queID: queConstants.phq_zipcode, ans: value }));
                    }}
                  />
                )}
              />
              <p className="PHQErrorMessage">
                {(errors.age || errors.zipcode) && (
                  <span>{getAgeZipcodeError(errors)}</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Step 4: Show all error messages in one place */}
      {/* {!isValid && (
        <p className="PHQErrorMessage">
          {errorMessages.split('\n').map((msg, index) => (
            <span key={index}>{msg}<br /></span>
          ))}
        </p>
      )} */}
      {/* <div className={`${style.warningMsg} show-desktop-hide-mobile`}>
        ■ We will use this to personalize results
      </div> */}

      <QueButtons currentQue={Que} first={true} disabled={!isValid} onClick={handleSubmit(onSubmit)} />

    </>
  );
}

export default PHQName;
