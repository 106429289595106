import { useEffect, useState } from "react";
import style from "./quizHeader.module.scss";
import { useDispatch, useSelector } from "react-redux";
import useScrollingUp from '../UseScrollingUp';
import { useNavigate } from 'react-router-dom';
import { restart_icon, quiz_close } from '@ph/common/assets/index';
import MD03UpgradeModal from "../../Report/ReportContainer/MD03UpgradeModal";
import { resetQuizData, resetQuizDataAfterStartOver } from '@ph/common/store/quizData.reducer';

const QuizHeader = (props) => {
    const scrolled = useScrollingUp();
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const [openExitModal, setExitModal] = useState(false)

    function handleGoBack() {
        setExitModal(false)
        window.open(process.env.REACT_APP_QUIZ_SHOPIFY_LINK, '_self')
    }

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('#desktopHeader');
        if (!header) return;
        const scrollTop = window.scrollY;
        if (header && header.classList) {
            scrollTop >= 250 ?
                header.classList.add(style.headerContainer) :
                header.classList.remove(style.headerContainer);
        }
    };

    const handleStartOver = () => {
        setOpenModal(false)
        dispatch(resetQuizDataAfterStartOver())
        navigate('/quiz')
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleOpenExitModal = () => {
        setExitModal(true)
    }

    return (
        <>
            <header className={scrolled ? style.stickyHeader + " " + style.headerContainer : style.headerContainer}>
                <div className={`${style.quizBox} viewBoxWidth smallViewBoxWidth`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
                    <div className={`${style.firstContainer} hand-cursor`} onClick={handleOpenModal}>
                        <img src={restart_icon} className={style.restartIcon} alt='' />
                        <span className={style.text}>Start Over</span>
                    </div>
                    <div className={style.buttonSection}>
                        <img src={quiz_close} className={style.closeIcon} alt='' onClick={handleOpenExitModal} />
                    </div>
                </div>
                <MD03UpgradeModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    title={'Starting Over?'}
                    subTitle={'Are you sure you want to start over? All of your responses will be cleared if you proceed.'}
                    cancelText={'Start Over'}
                    successText={'Continue Quiz'}
                    onCancel={() => handleStartOver(false)}
                    onSuccess={() => setOpenModal(false)}
                />
                <MD03UpgradeModal
                    openModal={openExitModal}
                    setOpenModal={setExitModal}
                    title={'Uh Oh!'}
                    subTitle={'You are doing good. We would suggest you complete the quiz to get the personalized product recommendations for your skin.'}
                    cancelText={'Exit Quiz'}
                    successText={'Continue Quiz'}
                    onSuccess={() => setExitModal(false)}
                    onCancel={() => handleGoBack()}
                />
            </header>
        </>
    );
};

export default QuizHeader;

