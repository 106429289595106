import style from './quizScalpArea.module.scss';
import { Row, Col } from 'antd';
import { QUIZ_SCALP_AREA } from '@ph/common/constants/Report/ReportConstants';
import AreaButton from '../AreaButton/AreaButton';
import { NO_ISSUES_TEXT } from '@ph/common/global/Constants';

const buttonConfigs = [
    {
        area: QUIZ_SCALP_AREA.SCALP,
        text: 'DANDRUFF/ITCHINESS',
        backgroundColor: '#25A18E',
        color: '#FFFFFF',
    },
    {
        area: QUIZ_SCALP_AREA.SCALP,
        text: 'REDNESS/IRRITATION',
        backgroundColor: '#F1DDFF',
        color: '#000000'
    },
    {
        area: QUIZ_SCALP_AREA.SCALP,
        text: 'HAIR LOSS',
        backgroundColor: '#EAFFA6',
        color: '#000000',
    },
    {
        area: QUIZ_SCALP_AREA.NO_ISSUE,
        text: NO_ISSUES_TEXT,
        backgroundColor: '#2E5ACC',
        color: '#FFFFFF',
    }
];



function QuizScalpArea({ values, onChange }) {
    // phq_face_area


    const handleFaceAreaClick = (newValue) => {
        let valuesCopy = values
        if (!Array.isArray(values))
            valuesCopy = []

        if (newValue == null) return;

        if (newValue === NO_ISSUES_TEXT) {
            // Add No issues
            if (values.includes(NO_ISSUES_TEXT)) {
                onChange([])
            } else {
                onChange([newValue])
            }
            return;
        }

        const updatedValues = valuesCopy.includes(newValue)
            ? valuesCopy.filter(item => item !== newValue) // Remove the value if it exists
            : [...valuesCopy, newValue]; // Add the new value

        // Remove no issue since other values are added
        const finalValues = updatedValues.filter(item => item !== NO_ISSUES_TEXT)
        onChange(finalValues);
    };

    const isIncludedArea = (area) => {
        return values?.includes(area)
    }
    const getProductBasedOnArea = (area) => {
        const products = reportData?.serum_recommendations.filter(product => product.skin_site === area)
        return products[0]
    }

    const isSelected = values.length == 0 ? false : values.includes(NO_ISSUES_TEXT) ? false : true

    return (
        <>
            <Row className={style.quizFaceArea}>
                <Col xs={24} lg={8}>
                    <div className={style.titleContainer}>
                        <div className={style.title}>Do you have <span className='font-Neue-medium'>scalp/hair</span> concerns? If so, choose the issues of concern. If you have no issues, select NO ISSUES.</div>
                    </div>
                </Col>
                <Col xs={24} lg={8}>
                    <div className={style.faceContainer}>
                        <svg width="337" height="438" viewBox="0 0 337 438" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                data="Scalp"
                                d="M102.839 76.6644C116.584 81.8614 127.958 92.939 133.52 106.547C140.221 122.935 138.466 141.649 143.891 158.493C147.196 168.796 154.034 178.871 164.359 182.062C185.192 188.512 215.052 155.895 224.557 190.29C232.626 219.511 219.542 253.816 233.401 281.419C235.863 286.319 239.464 290.946 244.524 292.998C251.385 295.756 259.477 293.089 265.084 288.234C273.449 280.986 281.518 268.928 287.786 259.742C291.114 254.864 309.28 220.606 313.953 220.606C323.048 206.998 332.051 196.08 334.581 179.782C338.388 155.188 336.861 129.728 330.319 105.726C317.304 58.0877 283.091 16.2844 233.72 4.40897C184.805 -7.35248 131.97 6.89347 90.5082 35.4082C81.0945 41.8815 64.2501 52.0474 73.3675 66.2022C79.1798 75.2284 93.9728 73.3366 102.839 76.6872V76.6644Z"
                                fill="#DEE0E0"
                                // fill={isSelected ? "#25A18E" : "#DEE0E0"}
                            />
                            <path d="M291.273 422.534C291.273 422.534 254.484 340.637 271.283 307.632C288.082 274.627 309.987 255.708 326.922 176.113C340.781 110.969 302.146 57.1538 266.155 36.161C230.164 15.1682 190.663 4.1589 121.051 20.1144C88.2741 27.6362 36.3733 55.8318 30.2418 111.835C25.8883 151.542 35.6211 169.891 16.7936 204.012C10.1151 216.116 3.68737 224.96 2.5249 231.661C1.31685 238.681 7.1064 241.781 17.9105 246.956C17.9105 246.956 23.0163 255.731 16.976 264.962C9.91001 275.789 14.879 279.322 23.8824 281.556C23.8824 281.556 20.0075 282.035 16.976 290.765C15.3805 295.392 17.8877 302.754 24.817 303.21C24.817 303.21 35.416 303.21 32.8403 335.759C31.7462 349.458 45.1488 361.538 68.7401 358.53C81.0942 356.957 106.304 349.139 123.559 339.68C123.559 339.68 130.465 367.579 132.653 382.235C136.3 406.556 136.027 419.411 134.18 436.939" stroke="#231F20" stroke-width="3.57858" />
                            <path d="M177.123 286C175.505 292.906 169.806 307.289 164.678 312.737C153.099 325.045 139.354 331.222 117.358 343.029" stroke="#231F20" stroke-width="3.57858" stroke-miterlimit="10" />
                            <path d="M200.486 175.474C200.486 175.474 213.365 166.152 227.998 171.622C242.632 177.115 248.49 191.384 241.469 211.693C234.449 232.002 219.792 250.83 213.957 254.864C208.099 258.898 196.384 260.54 188.771 241.872" stroke="#231F20" stroke-width="3.30506" stroke-miterlimit="10" />
                            <path d="M57.0693 59.6373C57.0693 59.6373 112.822 56.5602 135.616 83.8668C158.409 111.173 133.154 154.869 159.389 179.736C168.142 188.033 175.071 194.94 175.071 194.94" stroke="#231F20" stroke-width="3.57858" stroke-miterlimit="10" stroke-dasharray="10.71 8.94" />
                        </svg>
                    </div>
                </Col>
                <Col xs={24} md={24} lg={8} >
                    <div className={style.titleContainer}>
                        <div className={style.title}>Choose all that apply</div>

                        {buttonConfigs.map((config) => (
                            <AreaButton
                                key={config.text} // Use a unique key for each button
                                area={config.text}
                                isSelected={isIncludedArea(config.text)}
                                onClick={() => handleFaceAreaClick(config.text)}
                                backgroundColor={config.backgroundColor}
                                color={config.color}
                            >
                                {config.text}
                            </AreaButton>
                        ))}
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default QuizScalpArea

