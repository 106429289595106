import style from './quizFaceArea.module.scss';
import { Row, Col } from 'antd';
import { QUIZ_FACE_AREA } from '@ph/common/constants/Report/ReportConstants';
import AreaButton from '../AreaButton/AreaButton';
import { NO_ISSUES_TEXT } from '@ph/common/global/Constants';



const INIT_STATE = {
    area: '',
    goodBacteria: [],
    badBacteria: []
}

const buttonConfigs = [
    {
        area: QUIZ_FACE_AREA.T_ZONE,
        text: 'forehead / t-zone',
        backgroundColor: '#EAFFA6',
        color: '#000000',
    },
    {
        area: QUIZ_FACE_AREA.CHEEK,
        text: 'Cheeks',
        backgroundColor: '#F1DDFF',
        color: '#000000',
    },
    {
        area: QUIZ_FACE_AREA.CHIN,
        text: 'Chin',
        backgroundColor: '#25A18E', 
        color: '#FFFFFF',
    },
    {
        area: QUIZ_FACE_AREA.NO_ISSUE,
        text: NO_ISSUES_TEXT,
        backgroundColor: '#2E5ACC',
        color: '#FFFFFF',
    }
];


function QuizFaceArea({ values, onChange }) {
    // phq_face_area


    const handleFaceAreaClick = (newValue) => {
        let valuesCopy = values
        if (!Array.isArray(values))
            valuesCopy = []

        if (newValue == null) return;

        if (newValue === QUIZ_FACE_AREA.NO_ISSUE) {
            // Add No issues
            if (values.includes(QUIZ_FACE_AREA.NO_ISSUE)) {
                onChange([])
            } else {
                onChange([newValue])
            }
            return;
        }

        const updatedValues = valuesCopy.includes(newValue)
            ? valuesCopy.filter(item => item !== newValue) // Remove the value if it exists
            : [...valuesCopy, newValue]; // Add the new value

        // Remove no issue since other values are added
        const finalValues = updatedValues.filter(item => item !== QUIZ_FACE_AREA.NO_ISSUE)
        onChange(finalValues);
    };

    const isIncludedArea = (area) => {
        return values?.includes(area)
    }
    const getProductBasedOnArea = (area) => {
        const products = reportData?.serum_recommendations.filter(product => product.skin_site === area)
        return products[0]
    }

    return (
        <>
            <Row className={style.quizFaceArea}>
                <Col xs={24} lg={8}>
                    <div className={style.titleContainer}>
                        <div className={style.title}>If you experience skin blemishes or flare-ups on your <span className='font-Neue-medium'>face</span>, where do you most often find them? If no issues, select NO ISSUES.</div>
                    </div>
                </Col>
                <Col xs={24} lg={8}>
                    <div className={style.faceContainer}>
                        <svg width="260" height="420" viewBox="0 0 389 545" fill="none" xmlns="http://www.w3.org/2000/svg" className={style.faceHex}>
                            <g clip-path="url(#clip0_4627_1123)">
                                <path d="M33.4355 376.094C31.8258 413.528 30.8164 436.806 12.6187 477.954H377.46C377.46 477.954 354.46 438.021 354.678 376.094H33.4355Z" fill="#dee0e0" />
                                <path d="M33.4352 254.616L24.2409 251.639C24.2409 251.639 -1.58242 141.697 39.9422 74.7737C81.4668 7.85013 135.814 1.93868 185.701 6.28011C185.701 6.28011 207.391 -3.69972 242.968 1.50181C278.545 6.71699 333.534 37.462 354.678 85.5181C375.822 133.574 372.398 185.617 368.415 251.598L354.187 254.616C354.187 254.616 348.307 189.33 340.013 162.422C331.719 135.513 302.568 93.0678 261.057 83.0197C219.546 72.958 197.583 80.9582 197.583 80.9582C197.583 80.9582 168.868 76.5758 137.601 84.6717C106.322 92.7538 68.3983 114.133 50.6098 161.288C30.6524 214.218 33.4352 254.629 33.4352 254.629V254.616Z" fill="#dee0e0" />
                                <path d="M33.4354 254.616C33.4354 254.616 19.5621 247.23 11.309 254.616C3.06953 262.002 -3.43744 289.047 11.309 325.021C26.0554 360.994 29.4112 376.735 45.9583 378.633" fill="white" />
                                <path d="M33.4354 254.616C33.4354 254.616 19.5621 247.23 11.309 254.616C3.06953 262.002 -3.43744 289.047 11.309 325.021C26.0554 360.994 29.4112 376.735 45.9583 378.633" stroke="#2D2A26" stroke-width="3.7" stroke-miterlimit="10" />
                                <path d="M355.565 254.616C355.565 254.616 369.438 247.23 377.691 254.616C385.931 262.002 392.438 289.047 377.691 325.021C362.945 360.994 359.589 376.735 343.042 378.633" fill="white" />
                                <path d="M355.565 254.616C355.565 254.616 369.438 247.23 377.691 254.616C385.931 262.002 392.438 289.047 377.691 325.021C362.945 360.994 359.589 376.735 343.042 378.633" stroke="#2D2A26" stroke-width="3.7" stroke-miterlimit="10" />
                                <path d="M354.024 256.786C354.024 256.786 341.883 410.484 332.334 430.457C322.785 450.43 258.902 521.272 226.476 535.525C222.916 537.095 203.054 542.474 193.941 542.474C193.941 542.474 177.026 541.601 161.406 535.525C128.408 522.678 65.0975 450.43 55.5485 430.457C45.9994 410.484 33.8585 256.786 33.8585 256.786C33.8585 256.786 29.8752 148.264 92.3668 106.952C155.881 64.9713 197.556 80.9445 197.556 80.9445C197.556 80.9445 213.135 75.7976 251.631 81.2039C296.375 87.4839 328.46 127.308 340 162.421C351.855 198.504 355.797 235.434 354.01 256.786H354.024Z" fill="white" stroke="#2D2A26" stroke-width="3.7" stroke-miterlimit="10" />
                                <path d="M139.716 432.628C139.716 432.628 191.527 448.697 255.109 430.457" stroke="#2D2A26" stroke-width="3.7" stroke-miterlimit="10" />
                                <path d="M163.575 454.089C163.575 454.089 191.336 473.189 232.124 451.919" stroke="#2D2A26" stroke-width="3.7" stroke-miterlimit="10" />
                                <path d="M151.871 424.819C151.871 424.819 169.877 415.604 179.085 413.883C188.293 412.163 191.499 417.337 197.092 417.337C202.685 417.337 201.894 413.474 213.503 413.528C225.112 413.597 239.926 424.819 239.926 424.819" stroke="#2D2A26" stroke-width="3.7" stroke-miterlimit="10" />
                                <path d="M188.307 14.5261C202.617 30.8133 197.42 78.7875 197.42 78.7875" stroke="#2D2A26" stroke-width="3.7" stroke-miterlimit="10" />
                                <path fill={isIncludedArea(QUIZ_FACE_AREA.T_ZONE) ? "url(#Tzone_Pattern)" : "#EAFFA6"} opacity={isIncludedArea(QUIZ_FACE_AREA.T_ZONE) ? "1" : '0.3'} data="forehead" className='hand-cursor' onClick={() => handleFaceAreaClick(QUIZ_FACE_AREA.T_ZONE)} d="M56.7623 192.921C55.5209 199.132 54.0886 207.023 52.4925 216.949C50.0098 232.376 61.9188 246.397 77.5246 246.397H133.141C147.151 246.397 158.5 257.755 158.5 271.776V344.625C158.5 358.646 169.85 370.005 183.86 370.005H205.686C219.696 370.005 231.046 358.646 231.046 344.625V271.776C231.046 257.755 242.395 246.397 256.405 246.397H311.462C326.672 246.397 338.363 233.127 336.644 218C335.689 209.563 334.216 200.252 331.938 191.555C328.991 180.333 318.938 172.456 307.356 172.456H81.6443C69.5307 172.456 59.1359 181.029 56.7623 192.921Z" fill-opacity="0.95" />

                                <g data="cheeks" className='hand-cursor' onClick={() => handleFaceAreaClick(QUIZ_FACE_AREA.CHEEK)}>
                                    <path fill={isIncludedArea(QUIZ_FACE_AREA.CHEEK) ? "url(#Cheek_Pattern)" : "#F1DDFF"} fill-opacity={isIncludedArea(QUIZ_FACE_AREA.CHEEK) ? "1" : '0.5'} d="M75.069 276.568L141.953 292.746C150.711 294.862 156.836 301.989 156.931 310.167L157.518 361.527C157.6 368.312 153.494 374.565 146.864 377.773L94.5627 403.084C83.2266 408.572 69.2441 403.234 65.5882 392.108C59.1767 372.612 51.1555 339.765 49.6277 294.617C49.2185 282.452 62.0414 273.415 75.0827 276.568H75.069Z" />
                                    <path fill={isIncludedArea(QUIZ_FACE_AREA.CHEEK) ? "url(#Cheek_Pattern)" : "#F1DDFF"} fill-opacity={isIncludedArea(QUIZ_FACE_AREA.CHEEK) ? "1" : '0.5'} d="M313.931 276.568L247.047 292.746C238.289 294.862 232.164 301.989 232.069 310.167L231.482 361.527C231.4 368.312 235.506 374.565 242.136 377.773L294.438 403.084C305.774 408.572 319.756 403.234 323.412 392.108C329.823 372.612 337.845 339.765 339.372 294.617C339.782 282.452 326.959 273.415 313.918 276.568H313.931Z" />
                                </g>
                                <path fill={isIncludedArea(QUIZ_FACE_AREA.CHIN) ? "url(#Chin_Pattern)" : "#25A18E"} opacity={isIncludedArea(QUIZ_FACE_AREA.CHIN) ? "1" : '0.1'} data="chin" className='hand-cursor' onClick={() => handleFaceAreaClick(QUIZ_FACE_AREA.CHIN)} d="M201.28 519.784C234.62 519.784 266.568 486.404 282.924 466.103C289.213 458.294 286.989 446.703 278.3 441.693L273.88 439.14C267.85 435.659 260.32 436.396 255.041 440.928C243.95 450.458 223.175 465.284 201.294 465.284H187.734C165.853 465.284 145.077 450.458 133.987 440.928C128.707 436.396 121.177 435.659 115.148 439.14L110.728 441.693C102.038 446.703 99.8148 458.281 106.103 466.103C122.446 486.404 154.408 519.784 187.748 519.784H201.307H201.28Z" />
                            </g>
                            <defs>
                                <clipPath id="clip0_4627_1123">
                                    <rect width="389" height="545" fill="white" />
                                </clipPath>
                                {/* <pattern id="Tzone_Pattern" data-name="Tzone Pattern" x="0" y="0" width="66.4" height="66.4" patternUnits="userSpaceOnUse" viewBox="0 0 66.4 66.4">
                                    <g>
                                        <rect width="66.4" height="66.4" fill="#000" />
                                        <g>
                                            <rect width="66.4" height="66.4" fill="#EAFFA6" />
                                            <path d="M0,66.4v-.5l.5.5h-.5ZM17.1,66.4L0,49.3v.9l16.1,16.1h.9ZM33.7,66.4L0,32.8v.9l32.7,32.7s.9,0,.9,0ZM50.3,66.4L0,16.1v.9l49.3,49.3h.9ZM66.4,66L.5,0h-.5v.5l65.9,66h.5v-.5h0ZM16.1,0l50.3,50.3v-.9L17.1,0h-.9ZM32.7,0l33.7,33.7v-.9L33.7,0h-.9ZM49.3,0l17.1,17.1v-.9L50.3,0h-.9ZM65.9,0l.5.5v-.5h-.5Z" fill="#231f20" />
                                        </g>
                                    </g>
                                </pattern> */}
                                <pattern id="Tzone_Pattern" data-name="New Pattern Swatch 2" x="0" y="0" width="90.4" height="90.4" patternTransform="translate(-4155.1 -2090.26)" patternUnits="userSpaceOnUse" viewBox="0 0 33.21 33.21">
                                    <g>
                                        <rect class="cls-1" fill="#EAFFA6" strokeWidth="0px" width="80.4" height="80.4" />
                                        <path class="cls-9" strokeWidth="0px" fill="#231f20" d="M0,0v.23l.23-.23h-.23ZM8.53,0L0,8.53v-.46L8.07,0h.46ZM16.84,0L0,16.84v-.46L16.37,0h.46ZM25.14,0L0,25.14v-.46L24.67,0h.46ZM33.21.23L.23,33.21h-.23v-.23L32.98,0h.23v.23ZM8.07,33.21l25.14-25.14v.46l-24.67,24.67h-.46ZM16.37,33.21l16.84-16.84v.46l-16.37,16.37h-.46ZM24.67,33.21l8.53-8.53v.46l-8.07,8.07h-.46ZM32.98,33.21l.23-.23v.23h-.23Z" />
                                    </g>
                                </pattern>
                                {/* <pattern id="Cheek_Pattern" data-name="Cheek Pattern" x="0" y="0" width="66.4" height="66.4" patternUnits="userSpaceOnUse" viewBox="0 0 66.4 66.4">
                                    <g>
                                        <rect width="66.4" height="66.4" fill="#272525" />
                                        <g>
                                            <rect width="66.4" height="66.4" fill="#F1DDFF" />
                                            <path d="M0,66.4v-.5l.5.5h-.5ZM17.1,66.4L0,49.3v.9l16.1,16.1h.9ZM33.7,66.4L0,32.8v.9l32.7,32.7s.9,0,.9,0ZM50.3,66.4L0,16.1v.9l49.3,49.3h.9ZM66.4,66L.5,0h-.5v.5l65.9,66h.5v-.5h0ZM16.1,0l50.3,50.3v-.9L17.1,0h-.9ZM32.7,0l33.7,33.7v-.9L33.7,0h-.9ZM49.3,0l17.1,17.1v-.9L50.3,0h-.9ZM65.9,0l.5.5v-.5h-.5Z" fill="#000" />
                                        </g>
                                    </g>
                                </pattern> */}
                                <pattern id="Cheek_Pattern" data-name="Cheek Pattern" x="0" y="0" width="90.4" height="90.4" patternTransform="translate(-4155.1 -2090.26)" patternUnits="userSpaceOnUse" viewBox="0 0 33.21 33.21">
                                    <g>
                                        <rect class="cls-1" fill="#F1DDFF" strokeWidth="0px" width="80.4" height="80.4" />
                                        <path class="cls-9" strokeWidth="0px" fill="#231f20" d="M0,0v.23l.23-.23h-.23ZM8.53,0L0,8.53v-.46L8.07,0h.46ZM16.84,0L0,16.84v-.46L16.37,0h.46ZM25.14,0L0,25.14v-.46L24.67,0h.46ZM33.21.23L.23,33.21h-.23v-.23L32.98,0h.23v.23ZM8.07,33.21l25.14-25.14v.46l-24.67,24.67h-.46ZM16.37,33.21l16.84-16.84v.46l-16.37,16.37h-.46ZM24.67,33.21l8.53-8.53v.46l-8.07,8.07h-.46ZM32.98,33.21l.23-.23v.23h-.23Z" />
                                    </g>
                                </pattern>
                                {/* <pattern id="Chin_Pattern" data-name="Chin Pattern" x="0" y="0" width="66.4" height="66.4" patternUnits="userSpaceOnUse" viewBox="0 0 66.4 66.4">
                                    <g>
                                        <rect width="66.4" height="66.4" fill="#272525" />
                                        <g>
                                            <rect width="66.4" height="66.4" fill="#25A18E" />
                                            <path d="M0,66.4v-.5l.5.5h-.5ZM17.1,66.4L0,49.3v.9l16.1,16.1h.9ZM33.7,66.4L0,32.8v.9l32.7,32.7s.9,0,.9,0ZM50.3,66.4L0,16.1v.9l49.3,49.3h.9ZM66.4,66L.5,0h-.5v.5l65.9,66h.5v-.5h0ZM16.1,0l50.3,50.3v-.9L17.1,0h-.9ZM32.7,0l33.7,33.7v-.9L33.7,0h-.9ZM49.3,0l17.1,17.1v-.9L50.3,0h-.9ZM65.9,0l.5.5v-.5h-.5Z" fill="#000" />
                                        </g>
                                    </g>
                                </pattern> */}
                                <pattern id="Chin_Pattern" data-name="Chin Pattern" x="0" y="0" width="90.4" height="90.4" patternTransform="translate(-4155.1 -2090.26)" patternUnits="userSpaceOnUse" viewBox="0 0 33.21 33.21">
                                    <g>
                                        <rect class="cls-1" fill="#25A18E" strokeWidth="0px" width="80.4" height="80.4" />
                                        <path class="cls-9" strokeWidth="0px" fill="#231f20" d="M0,0v.23l.23-.23h-.23ZM8.53,0L0,8.53v-.46L8.07,0h.46ZM16.84,0L0,16.84v-.46L16.37,0h.46ZM25.14,0L0,25.14v-.46L24.67,0h.46ZM33.21.23L.23,33.21h-.23v-.23L32.98,0h.23v.23ZM8.07,33.21l25.14-25.14v.46l-24.67,24.67h-.46ZM16.37,33.21l16.84-16.84v.46l-16.37,16.37h-.46ZM24.67,33.21l8.53-8.53v.46l-8.07,8.07h-.46ZM32.98,33.21l.23-.23v.23h-.23Z" />
                                    </g>
                                </pattern>
                            </defs>
                        </svg>
                    </div>
                </Col>
                <Col xs={24} md={24} lg={8} >
                    <div className={style.titleContainer}>
                        <div className={style.title}>Choose zones that apply</div>

                        {buttonConfigs.map((config) => (
                            <AreaButton
                                key={config.area} // Use a unique key for each button
                                area={config.area}
                                isSelected={isIncludedArea(config.area)}
                                onClick={() => handleFaceAreaClick(config.area)}
                                backgroundColor={config.backgroundColor}
                                color={config.color}
                            >
                                {config.text}
                            </AreaButton>
                        ))}
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default QuizFaceArea

