import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import QuizHallmarks from '../QuizHallmarks/QuizHallmarks'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { scrollToContainer } from '@ph/common/global/Helpers'
import { HALLMARK_DESCRIPTION } from '@ph/common/constants/Report/ReportConstants'


// Validation schema
const validationSchema = Yup.object().shape({
  hallmarks: Yup.array().min(1, 'At least one hallmark must be selected'), // Ensure at least one hallmark is selected
});

function PHQHallmarks() {
  const dispatch = useDispatch();
  const Que = getQuestionByQueId(queConstants.phq_hallmarks);
  const hallmarksAns = useSelector(state => state.quizData[queConstants.phq_hallmarks]);

  const items = Object.entries(HALLMARK_DESCRIPTION)
    .filter(([key, value]) => value.title !== null && value.title !== '')
    .map(([key, value]) => value);
  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      hallmarks: hallmarksAns || [], // Default to existing answers or an empty array
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.hallmarks) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.hallmarks])

  return (
    <>
      <div className={style.questionContainer}>
        <div className={`${style.questionWrap}`}>
        <div className={style.questionWrapper}>
          <div className='PHQQuestionText'>
              Parallel has identified the <span className='font-Neue-medium'>7 Hallmarks of Skin Aging™</span>. <br className='show-desktop-hide-mobile' />
              Please rank them in order (1 = most important; 7 = least important).
          </div>
            <div className='PHQMessage'>■ CHOOSE ALL THAT APPLY</div>
          <div className='PHQSpacer'></div>

          <Controller
            name="hallmarks"
            control={control}
            render={({ field }) => (
              <QuizHallmarks
                values={field.value}
                items={items}
                onChange={(value) => {
                  field.onChange(value); // Update form state
                  dispatch(updateQuizData({ queID: queConstants.phq_hallmarks, ans: value })); // Update Redux state
                }}
              />
            )}
          />
            <p className="PHQErrorMessage" id="errorMessage">
              {errors.hallmarks && (
                <span >{errors.hallmarks.message}</span>
              )}
            </p>
        </div>
        </div>
      </div>

      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  );
}

export default PHQHallmarks;
