import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import QuizSelect from './QuizSelect/index'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomSelect from './QuizSelect/customSelect'

// Validation schema
const validationSchema = Yup.object().shape({
  gender: Yup.string().required('Gender is required'),
  ethnicity: Yup.array()
    .of(Yup.string())
    .min(1, 'Please select at least one ethnicity')
    .required('Ethnicity is required'),

});

function PHQGender() {
  const dispatch = useDispatch();
  const Que = getQuestionByQueId(queConstants.phq_gender);
  const ans = useSelector(state => state.quizData[queConstants.phq_gender]);
  const ethnicityQue = getQuestionByQueId(queConstants.phq_ethnicity);
  const ethnicityAns = useSelector(state => state.quizData[queConstants.phq_ethnicity]);

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      gender: ans || '',
      ethnicity: ethnicityAns || [],
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  const getGenderEthnicityError = (errors) => {
    if (errors.gender && errors.ethnicity) {
      return 'Gender and Ethnicity are required'
    } else if (errors.gender) {
      return 'Gender is required'
    } else {
      return 'Ethnicity is required'
    }
  }

  const errorMessages = Object.values(errors).map(error => error.message).join('\n');

  return (
    <>
      <div className={style.questionContainer}>
        <div className={`${style.questionWrap}`}>
        <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>{`We'd love to know more about what makes you unique.`}</div>
            <div className='PHQQuestionText'>{`My gender is `}
              <span className={style.selectWrapper}>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <CustomSelect
                  value={''}
                  options={['Male', 'Female', 'Non-Binary', 'Transgender']}
                  {...field}
                  onChange={(value) => {
                    field.onChange(value); // Update the form state
                    dispatch(updateQuizData({ queID: queConstants.phq_gender, ans: value })); // Update Redux state
                  }}
                  className={style.inlineBlock}
                />
              )}
            />
              </span>
              {/* <span className={style.selectWrapper}>
                <CustomSelect options={['Man', 'Woman', 'Transgender Man', 'Transgender Woman', 'Non-binary/gender queer/gender fluid']} />
              </span> */}
              <div className='PHQSpacer'></div>
              {' and race/ethnicity is  '}
              <span className={style.selectWrapper}>
                <Controller
                  name="ethnicity"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      value={field.value || []}
                      allowMultiple={true}
                      options={[
                        'White/Caucasian',
                        'East Asian',
                        'South/Southeast Asian',
                        'Hispanic/Latinx',
                        'Middle Eastern/North African',
                        'Black/African American',
                        'Native American',
                        'Pacific Islander'
                      ]}
                      {...field}
                      onChange={(selectedOptions) => {
                        field.onChange(selectedOptions); // Update the form state
                        dispatch(updateQuizData({ queID: queConstants.phq_ethnicity, ans: selectedOptions })); // Update Redux state
                      }}
                      className={style.inlineBlock}
                    />
                  )}
                />

              </span>
              <p className="PHQErrorMessage">
                {(errors.ethnicity || errors.gender) && (
                  <span >{getGenderEthnicityError(errors)}</span>
                )}
              </p>
            </div>
        </div>
        </div>
      </div>

      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  );
}

export default PHQGender;
