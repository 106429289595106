import React, { useEffect, useState } from 'react'
import style from "./PHProtocol.module.scss";
import { Row, Col, Checkbox, Typography } from 'antd';
import { useProtocolContext } from '@ph/subject/src/context/ProtocolContext';
import { PROTOCOL_FETCH } from '@ph/subject/src/api_services/Protocol/protocolFetch';
import ProtocolReadMoreDescContainer from './ProtocolReadMoreDescContainer';
import { convertPrice } from '@ph/common/global/Helpers';

const { Text, Paragraph } = Typography;

function StepProductMultiple({ rootProduct, stepId, stepProductsId, allowQuatity, minNumberOfSelection }) {

    const [selected, setSelected] = useState(false)
    const { selectedResult, modifySelectedResult, updateSelectionResult } = useProtocolContext()
    const [productData, setProductData] = useState(rootProduct)
    const [variantsData, setVariantsData] = useState([])
    const [selectedVariantId, setSelectedVariantId] = useState('')
    const [selectedVariantProduct, setSelectedVariantProduct] = useState({})
    const [loading, setLoading] = useState(false)

    const handleProductClick = (variantId) => {
        if (variantId === selectedVariantId) {
            setSelectedVariantId('')
            updateSelectionResult(stepId, stepProductsId, selectedVariantProduct, 0)
        } else {
            setSelectedVariantId(variantId)
            const varinatData = variantsData.filter(variant => variant.id === variantId)[0]
            const newProductVariant = {
                ...productData,
                product_id: variantId.replace('gid://shopify/ProductVariant/', ''),
                product_variant_id: variantId.replace('gid://shopify/ProductVariant/', ''),
                product_variant_title: varinatData?.title ?? ''
            }
            // setProductQuantity(1)
            setSelectedVariantProduct(newProductVariant)
            updateSelectionResult(stepId, stepProductsId, newProductVariant, 1)
        }

    }

    const handleMultipleProductCardClick = (event) => {
        event.stopPropagation()
        if (variantsData && variantsData.length > 0) {
            handleProductClick(variantsData[0].id)
        }
    }

    const isProductPresent = (productId) => {
        if (!selectedResult.hasOwnProperty(stepId) ||
            !selectedResult[stepId].hasOwnProperty(stepProductsId)) {
            return false; // stepId or stepProductId not present
        }

        const productsArray = selectedResult[stepId][stepProductsId];
        // console.log(stepId, stepProductsId, productId, productsArray.some(product => product.product_id === productId))
        return productsArray.some(product => productData.product_id === productId);
    };
    useEffect(() => {
        const result = isProductPresent(productData.product_id)
        setSelected(result)
    }, [selectedResult])


    useEffect(() => {
        if (rootProduct?.fetch_id) {
            setLoading(true)
            PROTOCOL_FETCH[rootProduct?.fetch_id].onFetch().then(res => {
                const updatedProduct = {
                    ...rootProduct,
                    ...res
                }
                setProductData(updatedProduct)
                setVariantsData(res.product_variants)
            })
                .catch(error => console.error(error))
                .finally(() => setLoading(false))
        } else {
            setProductData(rootProduct)
        }
    }, [])



    return (
        <Row className={`${style.productMultiple} ${selectedVariantId ? style.selected : ''}`} key={productData.product_id} onClick={handleMultipleProductCardClick}>
            <Col xs={24}>
                <div className={style.productDetail}>
                    <div>
                        {productData?.product_price && productData?.product_discount_price && (
                            <Text className={style.discountPrice}>{productData?.product_discount_price ? `$${parseInt(productData?.product_discount_price)}` : ''}</Text>
                        )}
                        <Text className={style.productPrice}>{productData?.product_price ? `$${convertPrice(productData?.product_price)}/mo` : 'Free'}</Text>
                    </div>
                    <Text className={style.productTitle}>{productData?.product_title}</Text>
                    <ProtocolReadMoreDescContainer
                        description={productData?.product_description}
                        selectedVariantId={selectedVariantId}
                    />
                </div>
                {variantsData && variantsData.map(variant =>
                    <SubProduct
                        title={variant?.title ?? ''}
                        key={variant.id}
                        isSelected={selectedVariantId === variant.id}
                        handleProductClick={() => handleProductClick(variant.id)}
                    />
                )} 
            </Col>
        </Row>
    )
}

export default StepProductMultiple

const SubProduct = ({ title, isSelected, handleProductClick }) => {
    return (
        <div className={style.productSelect}>
            <Text className={style.productTitle}>{title}</Text>
            {/* <Button type="secondary" className={`${style.addButton} ${isSelected ? style.selected : ''}`} onClick={handleProductClick}>
                {isSelected ? "ADDED" : "ADD"}
            </Button> */}
            <Checkbox className={`${style.checkbox} ${style.relative}`} checked={isSelected} onClick={handleProductClick} />

        </div>
    )
}

