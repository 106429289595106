import React, { useEffect, useState } from 'react';
import style from "./PHProtocol.module.scss";
import { Button, Typography, Collapse } from 'antd';
import { button_arrow, Back_Arrow_Border, read_more } from '@ph/common/assets/index';
import { useProtocolContext } from '@ph/subject/src/context/ProtocolContext';
import { convertPrice } from '@ph/common/global/Helpers';
import { CART_META_DATA } from '@ph/common/constants/QuizConstants';
const { Text } = Typography;


function ProtocolCart({ stepData }) {

    const { currStep, updateToPreviousStep, selectedResult } = useProtocolContext()
    const [allProducts, setAllProducts] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalDiscountPrice, setTotalDiscountPrice] = useState(0)
    const [variantIds, setVariantIds] = useState([])

    const MD03_PRODUCT_IDS = [111, 112, 113, 114]
    const getAllProductsAndSumPrices = (selectedResults) => {
        let allProducts = [];
        let variantIds = []
        let totalPrice = 0;
        let totalDiscount = 0;

        // Iterate over each stepId
        for (const stepId in selectedResults) {
            if (selectedResults.hasOwnProperty(stepId)) {
                const step = selectedResults[stepId];

                // Iterate over each stepProductId
                for (const stepProductId in step) {
                    if (step.hasOwnProperty(stepProductId)) {
                        const productsArray = step[stepProductId];

                        // Add each product to the allProducts array and sum the prices
                        productsArray.forEach(product => {
                            allProducts.push(product);
                            // if (product.product_id === 111 || product.product_id === 112 || product.product_id === 113 || product.product_id === 114) {
                            //     setSkinTestVariant(product.product_id)
                            // }
                            // if (product.product_id === 312) {
                            //     setMultiSerumPack(true)
                            // } else if (product.product_id === 311) {
                            //     setMultiSerumPack(false)
                            // }
                            const discountPrice = product?.product_discount_price || 0

                            if (MD03_PRODUCT_IDS.includes(product.product_id)) {
                                totalPrice = totalPrice + parseFloat(product.product_price);
                                totalDiscount = totalDiscount + parseFloat(discountPrice) + parseFloat(product.product_price) + parseFloat(95);
                            } else if (product?.quantity > 0) {
                                totalPrice = totalPrice + (parseFloat(product.product_price) * product.quantity);
                                totalDiscount = totalDiscount + (parseFloat(discountPrice) * product.quantity);
                            } else if (product?.product_price) {
                                totalPrice = totalPrice + (parseFloat(product.product_price) * 1);
                                totalDiscount = totalDiscount + (parseFloat(discountPrice) * 1);
                            }
                            if (product?.product_variant_id) {
                                variantIds.push({
                                    variant_id: product.product_variant_id,
                                    quantity: product?.quantity || 1,
                                    selling_plan: product.selling_plan
                                })
                            }

                        });
                    }
                }
            }
        }

        return { allProducts, totalPrice, variantIds, totalDiscount };
    };


    const getVariantUrl = () => {
        let url = ''
        if (variantIds && variantIds.length > 0) {
            variantIds.forEach(variant => {
                url += `items[][id]=${variant.variant_id}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant.selling_plan}%26`
            })
        }
        return url
    }

    const generateShopifyCartLink = () => {
        // Base URL for adding items to the cart
        const domain = `https://${process.env.REACT_APP_PROTOCOL_SHOPIFY_DOMAIN}`
        const baseUrl = `${domain}/cart/clear?return_to=/cart/add?`;
        window.open(`${baseUrl}${getVariantUrl()}return_to=/checkout`, '_self')

    }

    useEffect(() => {
        const { allProducts, totalPrice, variantIds, totalDiscount } = getAllProductsAndSumPrices(selectedResult)

        const filterAllProducts = allProducts.filter(product => ![211, 213, 311, 312, 313, 314].includes(product.product_id))
        setAllProducts(filterAllProducts)
        setTotalPrice(totalPrice)
        setVariantIds(variantIds)
        setTotalDiscountPrice(totalDiscount)
    }, [selectedResult])

    const getQuantityText = (id, quantity) => {
        if ([111, 112, 113, 114].includes(id)) {
            return '2X/YEAR'
        }
        if ([212].includes(id)) {
            return 'IN MONTH 2'
        }

        return quantity ?? '1'
    }

    const getVariantTitle = (product) => {
        if (product?.product_variant_title) {
            return `(${product?.product_variant_title})`
        }
        return ''
    }

    return (
        <div className={`${style.tabContain} ${currStep === stepData.id ? style.active : ''}`}>
            <h2 className='text-uv-blue font-NeueHGDP-light'>Your  <span className="font-Neue-medium">MD-03 Protocol™</span></h2>
            <div className={style.cartContainer}>
                <div className={`${style.cartItem} ${style.first}`}>
                    <Text className={`h5 font-Neue-medium`}>
                        Protocol Summary
                    </Text>
                </div>
                {allProducts.map((item, index) =>
                (MD03_PRODUCT_IDS.includes(item.product_id) ? <Md03CartProduct product={item} key={item.product_id} index={index} /> :
                    <div className={style.cartItem} key={item.product_id}>
                        <Text className={`${style.itemTitle} ${style.bold}`}>
                            {item.product_title} {getVariantTitle(item)} {`(${getQuantityText(item.product_id, item?.quantity)})`}
                        </Text>
                        <Text className={`${style.itemPrice} ${style.bold}`}>
                            {parseInt(item.product_price) === 0 ? item?.is_included ? <><ProductDiscount productData={item} />&nbsp;INCLUDED</> : "FREE" : <><ProductDiscount productData={item} />&nbsp;{`$${parseFloat(item.product_price) * (item?.quantity ?? 1)}/mo`}</>}
                        </Text>
                    </div>
                ))}
                {/* <div className={style.subTotal}>
                    <div className={style.disclaimer}>
                        <div className={style.icon}>
                            ■
                        </div>
                        <div className={style.text}>
                            All protocols require a 3-mo minimum commitment to complete the Introductory Experience. After you purchase, you will receive a short on-boarding call before your first shipment is sent.
                        </div>
                    </div>
                </div> */}
                <div className={style.total}>
                    <Text className={style.itemTitle}>
                        Total
                    </Text>

                    <Text className={style.itemPrice}>
                        <Text className={style.discountPrice}>
                            ${totalDiscountPrice}/mo
                        </Text>
                        <Text className={style.itemPrice}>
                            ${totalPrice}/mo
                        </Text>
                    </Text>
                </div>
                <div className={style.subTotal}>
                    <div className={style.line}></div>
                    <div className={style.disclaimer}>
                        
                        <div className={style.text}>
                            <span className={style.icon}>
                                ■
                            </span>
                            All protocols require a 3-mo minimum commitment to complete the Introductory Experience. After you purchase, you will receive a short on-boarding call before your first shipment is sent.
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.cartButtonContainer}>
                <img src={Back_Arrow_Border} alt='back' className={style.backButton} onClick={updateToPreviousStep} />
                <Button type="primary" className={style.addButton} onClick={generateShopifyCartLink}> Proceed To Checkout <img src={button_arrow} className='ph-btn-arrow' /></Button>
            </div>
            {/* <div className={style.buttonBox}>
                <div className={`${style.buttonContainer} ${style.showButton}`}>
                </div>
            </div> */}
        </div>
    )
}

export default ProtocolCart

const ProductDiscount = ({ productData }) => {
    if (productData?.product_discount_price) {
        return (
            <Text className={`${style.discountPrice}`}>
                {productData?.product_discount_price ? `$${parseInt(productData?.product_discount_price) * (productData?.quantity ?? 1)}` : ''}
            </Text>
        )
    }
    return <></>
}

const Md03CartProduct = ({ product, index }) => {

    const [isActive,setIsActive] = useState(false)

    const customExpandIcon = ({ isActive }) => {
        setIsActive(isActive)
        return <></>       
        };



    return (
        <>
            <Collapse
                size="small"
                expandIcon={customExpandIcon}
                items={[{
                    key: product.product_title, label: <div className={`${style.cartItem} ${style.md03Item}`}>
                        <Text className={`${style.itemTitle} ${style.bold} `}>
                            {product.product_title}

                            {isActive ? (
                                <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.4375 9.56201L8.71851 1.84302L0.999512 9.56201" stroke="#2E5ACC" stroke-width="2" stroke-linecap="round" />
                                </svg>
                            ) : (
                                    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1.71899L8.719 9.43799L16.438 1.71899" stroke="#2E5ACC" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                            )}
                            

                        </Text>

                        <Text className={`${style.itemPrice} ${style.bold}`}>
                            <Text className={`${style.discountPrice}`}>
                                {`$${convertPrice(parseFloat(product?.product_discount_price) + parseFloat(product?.product_price) + 95)}`}
                            </Text>
                            &nbsp;
                            {`$${convertPrice(product?.product_price)}/mo`}
                        </Text>

                    </div>, children: <>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={`${style.subItemTitle}`}>
                                {CART_META_DATA[product.product_variant_id]?.serum}
                            </Text>
                        </div>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={`${style.subItemTitle}`}>
                                {CART_META_DATA[product.product_variant_id]?.kitName}

                                <Text className={`${style.redDiscountPrice}`}>
                                    {`$${convertPrice(product?.product_discount_price)}`}
                                </Text>
                                <Text className={style.redItemPrice}>
                                    Included
                                </Text>
                            </Text>
                        </div>
                        {/* <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={style.itemTitle}>
                                DAILY RESET DUO: HOLY CALMING CLEANSER & SILK BARRIER SILK CREAM
                            </Text>
                        </div> */}
                        <div className={`${style.cartItem} ${style.subCartItem}`}>

                            <Text className={style.subItemTitle}>
                                {CART_META_DATA[product.product_variant_id]?.reportName}

                                <Text className={style.redItemPrice}>
                                    Included
                                </Text>
                            </Text>

                        </div>
                        <div className={`${style.cartItem} ${style.subCartItem}`}>
                            <Text className={style.subItemTitle}>
                                {CART_META_DATA[product.product_variant_id]?.consult}

                                <Text className={`${style.redDiscountPrice}`}>
                                    {`$95/mo`}
                                </Text>
                                <Text className={style.redItemPrice}>
                                    Included
                                </Text>
                            </Text>
                            {/* <div className={style.priceContainer}>
                                <Text className={`${style.discountPrice}`}>
                                    {`$95/TEST`}
                                </Text>
                                <Text className={style.itemPrice}>
                                    INCLUDED
                                </Text>
                            </div> */}
                        </div>

                    </>
                }]}
                className={style.customCollapse}
            />
        </>
    )
}