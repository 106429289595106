import { shopify_icon_light } from "@ph/common/assets/index";
import { PROTOCOL_STEP_CARD_TYPES, PROTOCOL_STEP_TYPES } from "./constants";
import { olivia_md03, quiz_cream, custom_active_3_md03 } from "@ph/common/assets/index";
import { PROTOCOL_IDS } from "@ph/subject/src/api_services/Protocol/protocolFetch"
import { PROTOCOL_SELLING_PLAN } from "@ph/subject/src/api_services/Protocol/protocolFetch";

export const PROTOCOL = {
    id: 'MD03',      //unique id
    fetch_id: PROTOCOL_IDS.MD03_PROTOCOL,  //  fetch details dynamically
    price_fetch_id: PROTOCOL_IDS.CUSTOM_PHAGE_ID,
    product_data: {
        product_id: 1,
        product_title: 'MD-03 Protocol™',
        product_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.",
        product_image: null,
        product_price: 195,
    },
    steps: [
        {
            id: 1,
            type: PROTOCOL_STEP_TYPES.PRODUCT,
            label: 'SKIN DISCOVERY (AREA OF CONCERN)',
            startLabel: 'MONTH 1 + EVERY 6 MONTHS',
            title: 'Choose area of concern',
            description: 'For every area of concern, testing is done in month 1 and every 6 months thereafter. Add more areas at a discount.',
            selectionLabel: 'Choose 1 or more',
            optional: true,
            step_data: [
                {
                    id: '11',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 1,
                    // allowQuatity: true,
                    showDiscountVariant: true,
                    defaultSelectId: [111],
                    data: [
                        {
                            fetch_id: PROTOCOL_IDS.SKIN_TEST_WITH_PACK_1,
                            product_id: 111,
                            product_title: 'Skin Discovery Test',
                            product_description: "Choose this area to address if you are concerned with acne, aging, discoloration, eczema, hidradenitis suppurativa, rosacea, or unexplained redness/irritation on your face.",
                            product_image: null,
                            product_price: 0,
                            discount_variant_id: PROTOCOL_IDS.SKIN_TEST_DISCOUNT_ID,
                            selling_plan: PROTOCOL_SELLING_PLAN.MD03_PROTOCOL_SELLING_PLAN,
                            extra_products_ids_to_add: [{
                                stepId: 3,
                                stepProductId: '31',
                                product_id: 311
                            }],
                            imageFillMobile: true
                        },
                        {
                            fetch_id: PROTOCOL_IDS.BODY_BLEMISH_PACK_1,
                            product_id: 112,
                            product_title: 'Body Blemish Discovery Test',
                            product_description: "Choose this area to address if you are concerned with back acne, aging, discoloration, eczema, folliculitis, hidradenitis suppurativa, or unexplained redness/irritation on your body.",
                            product_image: null,
                            product_price: 0,
                            discount_variant_id: PROTOCOL_IDS.BODY_BLEMISH_DISCOUNT_ID,
                            selling_plan: PROTOCOL_SELLING_PLAN.MD03_PROTOCOL_SELLING_PLAN,
                            extra_products_ids_to_add: [{
                                stepId: 3,
                                stepProductId: '31',
                                product_id: 312
                            }],
                            imageFillMobile: true
                        },
                        {
                            fetch_id: PROTOCOL_IDS.BODY_ODOR_PACK_1,
                            product_id: 113,
                            product_title: 'Odor Discovery Test',
                            product_description: "Choose this area to address if you are concerned with body odor.",
                            product_image: null,
                            product_price: 0,
                            discount_variant_id: PROTOCOL_IDS.BODY_ODOR_DISCOUNT_ID,
                            selling_plan: PROTOCOL_SELLING_PLAN.MD03_PROTOCOL_SELLING_PLAN,
                            extra_products_ids_to_add: [{
                                stepId: 3,
                                stepProductId: '31',
                                product_id: 313
                            }],
                            imageFillMobile: true
                        },
                        {
                            fetch_id: PROTOCOL_IDS.SCALP_HEALTH_PACK_1,
                            product_id: 114,
                            product_title: 'Scalp Health Test',
                            product_description: "Choose this area to address if you are concerned with hair loss, dandruff, or unexplained redness/irritation on your scalp.",
                            product_image: null,
                            product_price: 0,
                            discount_variant_id: PROTOCOL_IDS.SCALP_HEALTH_DISCOUNT_ID,
                            selling_plan: PROTOCOL_SELLING_PLAN.MD03_PROTOCOL_SELLING_PLAN,
                            extra_products_ids_to_add: [{
                                stepId: 3,
                                stepProductId: '31',
                                product_id: 314
                            }],
                            imageFillMobile: true
                        }
                    ],
                }
            ]
        },
        {
            id: 2,
            type: PROTOCOL_STEP_TYPES.PRODUCT,
            label: '1:1 CLINICAL GUIDANCE',
            startLabel: 'MONTH 2',
            title: 'Skin health report and microbiome essentials',
            description: `In month 2, you will receive your skin health report and 1:1 guidance. You'll also receive the Daily Reset Duo to begin using to prep your skin while waiting for your Custom Phage Serum.`,
            optional: true,
            step_data: [
                {
                    id: '21',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 3,
                    defaultSelectId: [211, 212, 213],
                    data: [
                        {
                            product_id: 211,
                            product_title: 'SKIN HEALTH REPORT',
                            product_description: 'Learn about your microbial balance and diversity score, as well as other health factors, alongside personalized recommendations.',
                            product_image: olivia_md03,
                            product_price: 0,
                            is_included: true,
                        },
                        {
                            product_id: 212,
                            product_title: 'DAILY RESET DUO',
                            product_description: 'In month two, you’ll receive the Daily Reset Duo—our Holy Calming Cleanser and Skin Barrier Silk Cream—to prepare your skin for the custom phage treatment in month three. You can continue with these products as add-ons for an additional cost.',
                            product_image: quiz_cream,
                            product_price: 0,
                            product_discount_price: 150,
                            is_included: true,
                        },
                        {
                            product_id: 213,
                            product_title: '1:1 Clinical guidance and 24/7 Support via Text with Parallel Microbiome Dermatology™ Doctor',
                            product_description: '',//1:1 clinical guidance and 24/7 ongoing support
                            product_image: null,
                            product_price: 0,
                            product_discount_price: 95,
                            is_included: true,
                        }

                    ],
                }
            ]
        },
        {
            id: 3,
            type: PROTOCOL_STEP_TYPES.PRODUCT,
            label: 'ACTIVE PHAGE SERUM(S)',
            startLabel: 'MONTH 3 + BEYOND',
            title: 'Active phage serum(s)',
            description: 'Based on your skin microbiome results, you will receive you Custom Active Phage Serum, which supports skin health and balance. For every area tested, you will receive a unique phage serum.',
            footerLabel: `<div>(For discounted family plans, reach out to us at <a href='mailto: hello@parallelhealth.io' rel="noreferrer">hello@parallelhealth.io</a>)</div>`,
            optional: true,
            step_data: [
                {
                    id: '31',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 1,
                    defaultSelectId: [311],
                    data: [
                        {
                            product_id: 311,
                            product_title: 'FACIAL ACTIVE PHAGE SERUM',
                            product_description: 'Based on your test results, you receive 1 custom phage serum per month to help control issue-causing bacteria.',
                            product_image: custom_active_3_md03,
                            product_price: 0,
                            is_included: true,
                            show_only_when_purchased: true,
                        },
                        {
                            product_id: 312,

                            product_title: 'BODY BLEMISH ACTIVE PHAGE SERUM',
                            product_description: 'Based on your test results, you receive 1 custom phage serum per month to help control issue-causing bacteria.',
                            product_image: custom_active_3_md03,
                            product_price: 0,
                            is_included: true,
                            show_only_when_purchased: true,
                        },
                        {
                            product_id: 313,

                            product_title: 'ODOR ACTIVE PHAGE SERUM',
                            product_description: 'Based on your test results, you receive 1 custom phage serum per month to help control issue-causing bacteria.',
                            product_image: custom_active_3_md03,
                            product_price: 0,
                            is_included: true,
                            show_only_when_purchased: true,
                        },
                        {
                            product_id: 314,

                            product_title: 'SCALP ACTIVE PHAGE SERUM',
                            product_description: 'Based on your test results, you receive 1 custom phage serum per month to help control issue-causing bacteria.',
                            product_image: custom_active_3_md03,
                            product_price: 0,
                            is_included: true,
                            show_only_when_purchased: true,
                        },
                        // {
                        //     product_id: 312,
                        //     fetch_id: PROTOCOL_IDS.BODY_BLEMISH_PACK_3,
                        //     product_title: 'CUSTOM ACTIVE PHAGE SERUM (3 Pack)',
                        //     product_description: 'Choose to receive three (3) if you will apply to facial areas more than 2x/day or need to cover multiple or large body area(s)',
                        //     product_image: null,
                        //     product_price: 295,
                        // }
                    ],
                }
            ]
        },
        {
            id: 4,
            type: PROTOCOL_STEP_TYPES.PRODUCT,
            label: 'PERSONALIZED ADD-ONS',
            startLabel: 'MONTH 1 + BEYOND',
            title: 'Choose your add-ons',
            description: 'With your MD-03™, access exclusive discounted pricing on monthly add-ons. You will begin receiving these add-ons from month 1 and every month thereafter.',
            selectionLabel: 'Add these super boosting products:',
            optional: true,
            step_data: [
                {
                    id: '41',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 0,
                    allowQuatity: true,
                    defaultSelectId: [],
                    data: [
                        // {
                        //     product_id: 411,
                        //     fetch_id: PROTOCOL_IDS.HOLY_CALMING_CLEANSER_ID,
                        //     product_title: 'HOLY CALMING CLEANSER',
                        //     product_description: 'This microbiome - friendly calming cleanser leaves skin refreshed ...',
                        //     product_image: null,
                        //     product_price: 32,
                        //     selling_plan: PROTOCOL_SELLING_PLAN.HOLY_CALMING_CLEANSER_SELLING_PLAN
                        // },
                        // {
                        //     product_id: 412,
                        //     fetch_id: PROTOCOL_IDS.SKIN_BARRIER_SILK_CREAM_ID,
                        //     product_title: 'SKIN BARRIER SILK CREAM',
                        //     product_description: 'This silky, rich microbiome - friendly moisturizing cream with peptides...',
                        //     product_image: null,
                        //     product_price: 85,
                        //     selling_plan: PROTOCOL_SELLING_PLAN.SKIN_BARRIER_SILK_CREAM_ID_SELLING_PLAN
                        // },
                        {
                            product_id: 413,
                            fetch_id: PROTOCOL_IDS.BLUE_PEPTIDE_CREAM_ID,
                            product_title: 'Blue Biotic™ Multi-Effect Peptide Cream (MIDI)',
                            product_description: 'Sparing no expense to formulate, this next - level peptide cream...',
                            product_image: null,
                            product_price: 205,
                            selling_plan: PROTOCOL_SELLING_PLAN.BLUE_PEPTIDE_CREAM_SELLING_PLAN
                        }
                    ],
                },
                {
                    id: '42',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: true,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 1,
                    rootProduct: {
                        product_id: 411,
                        fetch_id: PROTOCOL_IDS.PERSONALIZED_PRESCRIPTIONS,
                        product_title: "PERSONALIZED PRESCRIPTIONS (Choose Your One Main Concern)",
                        product_description: "Sparing no expense to formulate, this next-level peptide cream features potent copper peptides, SNAP-8, and Matrixl-3000, alongside a palette of key supportive actives and biotics that promote collagen production, the reduction of fine lines and wrinkles, and an improvement in dyspigmentation. For those who use toxins and fillers, this product is especially useful in between treatments to elongate effects.",
                        product_image: null,
                        product_price: 65,
                        selling_plan: PROTOCOL_SELLING_PLAN.PERSONALIZED_PRESCRIPTIONS_SELLING_PLAN
                    }
                },
                {
                    id: '43',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 0,
                    allowQuatity: true,
                    defaultSelectId: [],
                    data: [
                        {
                            product_id: 412,
                            fetch_id: PROTOCOL_IDS.DAILY_RESET_DUO_ID,
                            product_title: 'DAILY RESET DUO',
                            product_description: 'This silky, rich microbiome - friendly moisturizing cream with peptides...',
                            product_image: null,
                            product_price: 85,
                            selling_plan: PROTOCOL_SELLING_PLAN.RESET_DUO_SELLING_PLAN_ID
                        }
                    ],
                },
            ]
        },
        {
            id: 5,
            icon: shopify_icon_light,
            type: PROTOCOL_STEP_TYPES.CART,
            Title: 'Your MD-03',
            Sub_title: 'Protocol Summary',
            button_text: 'Add Protocol to Cart'
        }

    ],
}

export const MINI_PROTOCOL_PRODUCTS = [
    {
        product_title: 'Skin Discovery Test - Every 6 Months',
        product_description: 'The most advanced and comprehensive skin microbiome report, powered by whole genome sequencing',
        product_price: 0,
        discount_price: 435
    },
    {
        product_title: '1:1 Clinical Guidance with MD',
        product_description: 'Personalized evaluation and on-going support through our providers, including access to supplemental personalized prescriptions, as needed',
        product_price: 0,
    },
    {
        fetch_id: PROTOCOL_IDS.CUSTOM_PHAGE_ID,
        product_title: 'Active Phage Serum',
        product_description: 'Custom serum with nourishing ingredients and a potent precision phage cocktail designed for your skin microbiome',
        product_price: 195,
    }
]