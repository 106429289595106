import { getQuizData, getQuizDataById } from '@ph/subject/src/api_services/api'
import QuizLayout from '@ph/common/layouts/QuizLayout'
import { pageRoutes } from '@ph/common/utils/quizUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetQuizData, loadPrevAnswers, updateSessionId, resetQuizDataAfterStartOver } from '@ph/common/store/quizData.reducer'
import MD03UpgradeModal from '../Report/ReportContainer/MD03UpgradeModal'
import { useNavigate, useParams } from 'react-router-dom'

function Quiz() {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const orderNo = useSelector(state => state.quizData.currentQuestionNumber)
    const startOverClicked = useSelector(state => state.quizData.startOverClicked)
    const [welcomeOpen, setWelcomeOpen] = useState(false)
    const [loadPrevOpen, setLoadPrevOpen] = useState(false)
    const [quizId, setQuizId] = useState('')
    const sessionId = params.sessionId 

    const handleStartOver = () => {
        setWelcomeOpen(false)
        setLoadPrevOpen(false)
        dispatch(resetQuizData())
        navigate('/clarity-quiz')
    }

    const handleRecommendation = () => {
        setWelcomeOpen(false)
        navigate(`/clarity-quiz/result/${quizId}`)
    }

    useEffect(() => {
        if (sessionId) {
            getQuizDataById(sessionId).then(res => {
                if (res && res?.data) {
                    if (res.data.length > 0) {
                        const rawData = res.data[0]
                        dispatch(loadPrevAnswers(rawData.answers))
                        dispatch(updateSessionId(rawData.session_id))
                        if (rawData.submission_id) {
                            setWelcomeOpen(true)
                            setQuizId(sessionId)
                        } else {
                            setLoadPrevOpen(true)
                        }
                    }
                }
            }).catch(error => console.log(error))
        } else if (orderNo === 1) {
            getQuizData().then(res => {
                if (res && res?.data) {
                    if (res.data.length > 0) {
                        if (!startOverClicked) {
                            setWelcomeOpen(true)
                        }
                        const newQuizId = res.data[0].session_id || res.data[0].quiz_submission_id
                        setQuizId(newQuizId)
                    }
                }
            }).catch(error => console.log(error))
        } else if (orderNo > 1) {
            setLoadPrevOpen(true)
        }
    }, [sessionId])

    return (<>
        <PageRenderer order={orderNo} />
        <MD03UpgradeModal
            openModal={welcomeOpen}
            setOpenModal={setWelcomeOpen}
            title={'Welcome Back'}
            subTitle={'You seem to have taken and completed the quiz before. We are happy to see you back on the quiz.'}
            cancelText={'Start Over'}
            successText={'See Recommendations'}
            onCancel={() => handleStartOver(false)}
            onSuccess={() => handleRecommendation()}
        />
        <MD03UpgradeModal
            openModal={loadPrevOpen}
            setOpenModal={setLoadPrevOpen}
            title={'Welcome Back'}
            subTitle={'We are happy to see you back on the quiz. Lets get going.'}
            cancelText={'Start Over'}
            successText={'Continue Quiz'}
            onCancel={() => handleStartOver(false)}
            onSuccess={() => setLoadPrevOpen(false)}
        />
    </>
    )

}

export default Quiz

const PageRenderer = ({ order }) => {
    // Function to find the page with the matching order
    const findPageByOrder = (order) => {
        for (const route of pageRoutes) {
            const page = route.pages.find(page => page.order === order);
            if (page) return page;
        }
        return null;
    };

    const page = findPageByOrder(order);

    if (!page) {
        return <BlankComponent />;
    }

    // Render the component for the matching page
    return <QuizLayout orderNo={order}>{page.component}</QuizLayout>;

};


const BlankComponent = () => {
    return <></>
}