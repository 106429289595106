import { useEffect, useState } from 'react';
import { Button, Modal, Typography, Row, Form, Input } from "antd";
import style from '../../AdminLabPortal/ValidateKitFormContent/ValidateKitFormContent.module.scss';
import { postAssignKitcode, updateRegistration } from '../ServiceDashboardAPIService';
import { getFormDataPayload, handleErrorMsg, handleSuccessMsg } from '@ph/common/global/Helpers';
const { Text } = Typography;

const SHOFITY_ERROR_MSG = "Shopify  account does not exist. Please enter first name and last name to create one."

function UpdateOrderUser({
    open,
    closeModal,
    selectedRecord,
    onCallback,
    cancelBtnAction = null,
    proceedBtnClass = '',
    modalTitle = '',
    closable = false,
    assignFlag = false,
    initCreateNew = false
}) {

    const [verified, setVerified] = useState(false)
    const [createNew, setCreateNew] = useState(initCreateNew)
    const [newEmail, setNewEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [customerId, setCustomerId] = useState('')
    const [verificationError, setVerificationError] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)


    const resetForm = () => {
        setVerified(false)
        setCreateNew(false)
        setNewEmail('')
        setFirstName('')
        setLastName('')
        setCustomerId('')
        setVerificationError('')
    }

    const handleSuccess = () => {
        resetForm()
        closeModal()
        onCallback()
    }

    const onVerify = () => {
        if (newEmail) {
            const requestBody = {
                email: newEmail,
                verify_customer: true,
                create_customer: false,
                send_email_invite: false
            }
            const payload = getFormDataPayload(requestBody)

            updateRegistration(selectedRecord.kitcode, payload)
                .then(res => {
                    if (res?.data?.customer) {
                        const customer = res.data.customer
                        setFirstName(customer.first_name)
                        setLastName(customer.last_name)
                        setCustomerId(customer.id)
                        setVerificationError(false)
                        setCreateNew(false)
                        setVerified(true)
                        handleSuccessMsg("User is verified!")
                    }
                })
                .catch(error => {
                    handleErrorMsg(error, "Unable to verify user!")
                    setVerificationError(true)
                    setCreateNew(true)
                    setFirstName('')
                    setLastName('')
                })
        }
    }

    const onSave = () => {
        if (createNew) {
            if (firstName) {
                setSaveLoading(true)
                const requestBody = {
                    email: newEmail,
                    first_name: firstName,
                    last_name: lastName,
                    verify_customer: false,
                    create_customer: true,
                    send_email_invite: true
                }
                updateRegistration(selectedRecord.kitcode, getFormDataPayload(requestBody))
                    .then(res => {
                        setTimeout(() => {
                            handleSuccessMsg("User created and Updated successfully!")
                            handleSuccess()
                            setSaveLoading(false)
                        }, [process.env.REACT_APP_SHOPIFY_USER_CREATION_DOWNTIME])
                    })
                    .catch(error => {
                        handleErrorMsg(error, "Unable to create user!")
                        setVerificationError(false)
                        setSaveLoading(false)
                    })
            }
            else {
                handleErrorMsg({ error: '' }, "Please fill all fields!")
            }

        } else {
            const requestBody = {
                email: newEmail,
                first_name: firstName,
                last_name: lastName,
                verify_customer: false,
                create_customer: false,
                send_email_invite: false,
                customer_id: customerId
            }
            updateRegistration(selectedRecord.kitcode, getFormDataPayload(requestBody))
                .then(res => {
                    handleSuccessMsg("User updated successfully!")
                    handleSuccess()
                })
                .catch(error => {
                    handleErrorMsg(error, "Unable to update user!")
                    setVerificationError(false)
                })
        }
    }

    const onAssign = () => {
        if (firstName && newEmail) {
            setSaveLoading(true)
            let requestBody = {
                kitcode: selectedRecord.kitcode,
                gifting_to_first_name: firstName,
                gifting_to_last_name: lastName,
                gifting_to_email: newEmail
            }
            if (selectedRecord?.order_id) {
                requestBody = { ...requestBody, order_id: selectedRecord.order_id }
            }
            if (selectedRecord?.line_item_uid) {
                requestBody = { ...requestBody, line_item_uid: selectedRecord.line_item_uid }
            }
            postAssignKitcode(getFormDataPayload(requestBody))
                .then(res => {
                    setTimeout(() => {
                        handleSuccessMsg("User updated successfully!")
                        handleSuccess()
                        setSaveLoading(false)
                    }, [process.env.REACT_APP_SHOPIFY_USER_CREATION_DOWNTIME])
                })
                .catch(error => {
                    handleErrorMsg(error, "Unable to update user!")
                    setSaveLoading(false)
                })
        }
        else {
            handleErrorMsg({ error: '' }, "Please fill all fields!")
        }
    }

    const handleCloseModal = () => {
        if (assignFlag) {
            setNewEmail('')
            setFirstName('')
            setLastName('')
        } else {
            resetForm()
        }
        closeModal()
    }

    useEffect(() => {
        setCreateNew(initCreateNew)
        if (assignFlag) {
            if (selectedRecord?.assigned_to_first_name && selectedRecord?.assigned_to_email) {
                setFirstName(selectedRecord?.assigned_to_first_name)
                setLastName(selectedRecord?.assigned_to_last_name)
                setNewEmail(selectedRecord?.assigned_to_email)
            } else {
                setNewEmail('')
                setFirstName('')
                setLastName('')
            }
        } else {
            resetForm()
        }
    }, [initCreateNew, assignFlag, selectedRecord, open])

    return (
        <Modal open={open}
            onCancel={closeModal}
            afterClose={closeModal}
            closeIcon={''} closable={closable} maskClosable={false}
            footer={''}
            style={{ top: '30%' }}
        >
            <div className='text-center' style={{ padding: '24px' }}>
                <Text className="body-txt-lg"> {modalTitle} </Text>
            </div>
            <Form>
                <Row type="flex" justify="center" align="middle">
                    {!assignFlag && <CustomInput label={"Current Email ID"} value={selectedRecord.registered_by} disabled={true} />}
                    <CustomInput label={assignFlag ? "Email ID" : "New Email ID"} value={newEmail} onChange={(e) => setNewEmail(e.target.value)} onVerify={assignFlag ? null : onVerify} />
                    {verificationError && <CustomInput error={SHOFITY_ERROR_MSG} />}
                    <CustomInput label={"First Name"} value={firstName} onChange={(e) => setFirstName(e.target.value)} disabled={!createNew} />
                    <CustomInput label={"Last Name"} value={lastName} onChange={(e) => setLastName(e.target.value)} disabled={!createNew} />
                </Row>
            </Form>

            <div className={style.confirmationActionBtnContainer}>
                <Button className={`ph-btn transparent`}
                    onClick={handleCloseModal} >
                    <Text className={`text-dark`}>NO, GO BACK</Text>
                </Button>
                <Button className={`ph-btn ${proceedBtnClass}`} type='primary'
                    loading={saveLoading} disabled={(!verified && !createNew)} onClick={assignFlag ? onAssign : onSave}>
                    <Text className={`text-light`}>{createNew ? assignFlag ? ' ASSIGN ' : 'CREATE & SAVE' : 'SAVE & CONTINUE'} </Text>
                </Button>
            </div>
        </Modal>
    );
}

export default UpdateOrderUser;

const CustomInput = ({ label, value, onChange, onVerify, disabled = false, error = false }) => {
    return (
        <Row type="flex" justify="left" align="middle" style={{ width: '94%', marginBottom: '24px' }} className={style.datepickerContainer}>
            <div style={{ minWidth: '140px' }}>
                {!error && <Text className="body-txt-lg">{label}</Text>}
            </div>
            <div style={{ width: '50%' }}>
                {!error ? (<Input
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />) : (
                    <Text className="body-txt-md text-error" >
                        {error}
                    </Text>
                )}
            </div>
            {!error && (
                <div style={{ minWidth: '40px', marginLeft: '12px' }}>
                    {onVerify && (
                        <Text className="body-txt-lg td-underline hand-cursor" onClick={onVerify} >
                            Verify
                        </Text>
                    )}
                </div>
            )}
        </Row>
    )
}