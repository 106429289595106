import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import ButtonOptions from './QuiZButtonOption/index'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { scrollToContainer } from '@ph/common/global/Helpers'
import { useEffect } from 'react'

// Validation schema
const validationSchema = Yup.object().shape({
  doctorSkincare: Yup.string().required('At least one option must be selected'), // Ensure at least one issue is selected
});

const data = {
  'I already have a prescription (Rx), but I’m open to adding more to achieve my goals': 'A',
  'I’ve got a prescription (Rx) but I’m good for now—no more prescriptions needed': 'B',
  'I’m prescription (Rx)-curious and open to exploring new prescription wonders': 'C',
  'I’m all about the natural route—no prescription (Rx) needed for me': 'D'
}

const newData = {
  '': '',
  'A': 'I already have a prescription (Rx), but I’m open to adding more to achieve my goals',
  'B': 'I’ve got a prescription (Rx) but I’m good for now—no more prescriptions needed',
  'C': 'I’m prescription (Rx)-curious and open to exploring new prescription wonders',
  'D': 'I’m all about the natural route—no prescription (Rx) needed for me'
}
function PHQDoctorSkincare() {
  const Que = getQuestionByQueId(queConstants.phq_doctor_skincare)
  const ans = useSelector(state => state.quizData[queConstants.phq_doctor_skincare])
  const dispatch = useDispatch();

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      doctorSkincare: ans || '', // Default to existing answers or an empty array
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  useEffect(() => {
    if (errors?.doctorSkincare) {
      scrollToContainer('errorMessage')
    }
  }, [errors?.doctorSkincare])

  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>Looking for some doctor-approved skincare? <br className='show-desktop-hide-mobile' />
              Let us know if you're on the quest for specialty, compounded prescriptions made just for you. </div>
            <div className='PHQMessage'>We offer both prescriptions and clinical grade skin care products</div>
            <div className='PHQSpacer'></div>

            <div className={`${style.midButtonOption}`}>
              <Controller
                name="doctorSkincare"
                control={control}
                render={({ field }) => (
                  <ButtonOptions
                    question={`What are your current skin concerns?`}
                    options={['I already have a prescription (Rx), but I’m open to adding more to achieve my goals', 'I’ve got a prescription (Rx) but I’m good for now—no more prescriptions needed', 'I’m prescription (Rx)-curious and open to exploring new prescription wonders', 'I’m all about the natural route—no prescription (Rx) needed for me']}
                    values={newData[ans]}
                    onChange={(value) => {
                      const type = data[value]
                      field.onChange(type);
                      dispatch(updateQuizData({ queID: queConstants.phq_doctor_skincare, ans: type }))
                    }}
                    desktopCol={1}
                    background='linear-gradient(133.34deg, #F1DDFF 32.15%, #25A18E 107.21%)'
                    backgroundMobile='linear-gradient(160deg, #F1DDFF 32.15%, #25A18E 107.21%)'
                    multiSelection={true}
                  />
                )}
              />
            </div>
            <p className="PHQErrorMessage" id="errorMessage">
              {errors.doctorSkincare && (
                <span >{errors.doctorSkincare.message}</span>
              )}
            </p>
          </div>
        </div>
      </div>
      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  )
}

export default PHQDoctorSkincare