import QueButtons from '../QueButtons/index'
import style from './questions.module.scss'
import { getQuestionByQueId } from '@ph/common/utils/quizUtils'
import { queConstants } from '@ph/common/constants/QuizConstants'
import QuizSelect from './QuizSelect/index'
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizData } from '@ph/common/store/quizData.reducer'
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Validation schema
const validationSchema = Yup.object().shape({
  curiosity: Yup.string().required('Please select a answer'), // Ensure at least one issue is selected
});


// import Components from "@ph/common/components";
function PHQSkincareAdventure() {
  const Que = getQuestionByQueId(queConstants.phq_skincare_adventure)
  const ans = useSelector(state => state.quizData[queConstants.phq_skincare_adventure])

  const dispatch = useDispatch();

  // Using React Hook Form
  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      curiosity: ans || null, // Default to existing answers or an empty array
    },
  });

  const onSubmit = (data) => {
    // Do handleSubmit nothing
  };

  return (
    <>
      <div className={style.questionContainer} >
        <div className={`${style.questionWrap}`}>
          <div className={style.questionWrapper}>
            <div className='PHQQuestionText'>Navigating the skincare universe can be quite the adventure.<br className='show-desktop-hide-mobile' />
              When it comes to my skin health journey:</div>
            <span className={`${style.selectWrapper} ${style.medium}`}>
              <Controller
                name="curiosity"
                control={control}
                render={({ field }) => (
                  <QuizSelect
                    value={field.value}
                    placeholder="select"
                    options={[
                      'I would love a co-pilot please.',
                      'I want guidance sometimes; a little help goes a long way.',
                      'I like my independence.'
                    ]}
                    {...field}
                    onChange={(value) => {
                      field.onChange(value); // Update the form state
                      dispatch(updateQuizData({ queID: queConstants.phq_skincare_adventure, ans: value }))
                    }}
                    style={{ width: '100%' }}
                  />
                )}
              />
            </span>
            <p className="PHQErrorMessage" id="errorMessage">
              {errors?.curiosity && (
                <span>{errors?.curiosity.message}<br /></span>
              )}
            </p>
          </div>
        </div>
      </div>
      <QueButtons currentQue={Que} disabled={!isValid} onClick={handleSubmit(onSubmit)} />
    </>
  )
}

export default PHQSkincareAdventure